import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as Logocarrito } from "../svg/logo-carrito.svg";

function AnuncioTienda() {
  const theme = useTheme();

  return (
    <Box
      component="section"
      sx={{
        color: theme.palette.common.white,
        background: `url(${process.env.PUBLIC_URL}/imagenes/backgraund-02.webp),url(${process.env.PUBLIC_URL}/imagenes/backgraund-02.png)`,
        backgroundColor: theme.palette.common.black,
        backgroundSize: "cover",
        padding: theme.spacing(8, 2),
      }}
    >
      <Grid>
        <Grid item sx={{ justifyContent: "center" }}>
          <Box
            sx={{
              marginRight: "8px",
              marginTop: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              padding: theme.spacing(4, 0, 0),
            }}
          >
            <Box
              sx={{
                width: "48px",
                marginRight: "8px",
                marginTop: "4px",
                "& svg": { fontSize: "16px" },
              }}
            >
              <Logocarrito />
            </Box>
            <Typography
              component="h2"
              sx={{
                fontWeight: "900",
                borderBottom: `2px solid ${theme.palette.common.white}`,
                fontSize: { xs: "1.3rem", md: "2.2rem" },
              }}
            >
              Tienda en Linea
            </Typography>
          </Box>

          <Typography
            variant="body1"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontWeight: "lighter",
              paddingBottom: theme.spacing(3),
            }}
          >
            Envios solo en Mazatlán, Sinaloa.
          </Typography>
          <Typography
            component="h2"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "900",
              textAlign: "center",
              fontSize: { xs: "2.2rem", md: "2.2rem" },
            }}
          >
            Tus cortes favoritos
          </Typography>
          <Typography
            component="h3"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "900",
              letterSpacing: "0.3em",
              wordSpacing: "0.3em",
              paddingBottom: theme.spacing(4),
              fontSize: { xs: "1.5rem", md: "3.75rem" },
            }}
          >
            A UN CLIC
          </Typography>
          <Typography
            variant="h2"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "900",
              paddingBottom: theme.spacing(4),
            }}
          >
            <Button
              variant="contained"
              sx={{ backgroundColor: "#ae0730", textAlign: "center" }}
              href="/productos/"
            >
              Conosca nuestros productos
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
export { AnuncioTienda };
export default AnuncioTienda;
