import React from "react";
import { Box } from "@mui/material";
import { ReactComponent as ReactLogo } from "../svg/logo-mi-esperanza-completo.svg";

type LOGOSIZE = "inherit" | "large" | "medium" | "small" | undefined;

export interface LogoProps {
  size?: LOGOSIZE;
  customSize?: string | undefined;
}

const sizeFont = [
  { name: "inherit", size: "inherit" },
  { name: "large", size: "2.1875rem" },
  { name: "medium", size: "1.5rem" },
  { name: "small", size: "1.25rem" },
];

function Logo({ size, customSize }: LogoProps) {
  const isSizeFont = (findSize: any) => findSize.name === size;

  const sizeLogo =
    customSize === undefined ? sizeFont.find(isSizeFont)?.size : customSize;

  return (
    <Box
      sx={{
        "& .MuiSvgIcon-root": {
          userSelect: "none",
          width: "1em",
          height: "1em",
          display: "inline-block",
          fill: "currentColor",
          flexShrink: "0",
          transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          fontSize: `${sizeLogo}`,
        },
      }}
    >
      <ReactLogo className="MuiSvgIcon-root" />
    </Box>
  );
}

Logo.defaultProps = {
  size: "medium",
  customSize: undefined,
};
export { Logo };
export default Logo;
