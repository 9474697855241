import React from "react";
import { Breadcrumbs, Stack } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

interface IconBreadcrumbsProps {
  breadcrumbs: any[];
}

function IconBreadcrumbs({ breadcrumbs }: IconBreadcrumbsProps) {
  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon />}
        aria-label="breadcrumb"
        sx={{
          "& .MuiBreadcrumbs-separator": {
            marginLeft: "0px",
            marginRight: "0px",
          },
          "& ,& p": {
            fontSize: "14px",
            "& p": {
              fontWeight: "bold",
              webkitTouchCallout: "none",
              webkitUserSelect: "none",
              khtmlUserSelect: "none",
              mozUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
            },
          },
        }}
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
export { IconBreadcrumbs };
export default IconBreadcrumbs;
