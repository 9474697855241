import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";

import { usePeticiones, useLocalstorage } from "../useComplements";

interface state_login {
  user: string;
  password: string;
  showPassword: boolean;
}

interface state_message {
  message: string;
  accion: "info" | "warning";
}

function PageLogin() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { peticion, statePeteiciones } = usePeticiones();
  const { updateLoginLS } = useLocalstorage();

  const [values, setValues] = React.useState<state_login>({
    user: "",
    password: "",
    showPassword: false,
  });

  const [menssage, setMenssage] = React.useState<state_message>({
    message: "",
    accion: "info",
  });

  const handleChange =
    (prop: keyof state_login) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
      console.log(event.target.value);
    };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const signIn = (frm: state_login) => {
    const body = { user: frm.user, password: frm.password };

    if (frm.user === "") {
      setMenssage({
        ...menssage,
        message: "ingrese un usuario.",
        accion: "warning",
      });
    } else if (frm.password === "") {
      setMenssage({
        ...menssage,
        message: "ingrese un password.",
        accion: "warning",
      });
    } else {
      peticion({ accion: "authenticate", formData: body }).then(
        (datos) => {
          if (datos.success) {
            updateLoginLS(datos.data);
            navigate("/dashboard");
          } else {
            setMenssage({
              ...menssage,
              message: "Error de Ingreso.",
              accion: "warning",
            });
          }
        },
        (error) => {}
      );
    }
  };

  const handClickLogin = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    signIn(values);
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyCcontent: "center",
          height: "100vh",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: theme.spacing(2, 2, 1),
            "& div": { margin: theme.spacing(1, 0) },
          }}
        >
          <Avatar
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
            sx={{ bgcolor: theme.palette.primary.main }}
          />
          <Typography component="div" variant="h5" sx={{ fontWeight: "bold" }}>
            Login
          </Typography>
          <Box component="form" sx={{ width: "100%" }}>
            <TextField
              fullWidth
              label="[ Usuario ]"
              color="primary"
              size="small"
              autoFocus
              focused
              id="user"
              autoComplete="i-user"
              value={values.user}
              onChange={handleChange("user")}
              required
              margin="normal"
            />

            <TextField
              fullWidth
              label="[ Contraseña ]"
              color="primary"
              size="small"
              focused
              id="password"
              value={values.password}
              onChange={handleChange("password")}
              margin="normal"
              required
              type={values.showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <label htmlFor="password">
                      <IconButton
                        aria-label="password-visibility"
                        component="span"
                        edge="end"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </label>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              disabled={statePeteiciones.isLoading}
              variant="contained"
              fullWidth
              color="primary"
              onClick={handClickLogin}
              type="submit"
            >
              Ingresar
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}
export { PageLogin };
export default PageLogin;
