import React from "react";
import {
  Box,
  TextField,
  Grid,
  Button,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";

import { useAppProvider } from "../providers/Appcontext";
import { usePeticiones, useValidaForm } from "../useComplements";

interface inputsState {
  nombre: string;
  telefono: string;
  telefono2: string;
  email: string;
  activo: boolean;
}

function Formulario() {
  const { peticionAsync } = usePeticiones();
  const { validaContenido } = useValidaForm();
  const { setNotificacionMsg, setNotificacionAcc } = useAppProvider();

  const iniDatos = () => {
    const datos: inputsState = {
      nombre: "",
      telefono: "",
      telefono2: "",
      email: "",
      activo: false,
    };
    return datos;
  };

  const [inputs, setInputs] = React.useState<inputsState>(iniDatos());

  const [mount, setMount] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!mount) {
      setMount(true);
      setInputs(iniDatos());
    }
  }, [mount]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setMount(false);
    }, 10);
    return () => clearTimeout(timer);
  }, []);

  const fetchdata = async (info: any) => {
    const result: any = await peticionAsync({
      accion: "informacion",
      formData: info,
    });

    if (result.success) {
      setNotificacionAcc("success");
      setNotificacionMsg("Envio Sactisfactorio");
      setInputs(iniDatos());
    } else {
      setNotificacionAcc("warning");
      setNotificacionMsg("Fallo el envio intente mas tarde.");
      setInputs({ ...inputs, activo: false });
    }
  };

  const handleChange =
    (prop: keyof inputsState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputs({ ...inputs, [prop]: event.target.value });
    };

  const handleClickSend = () => {
    setInputs({ ...inputs, activo: true });

    const body = {
      nombre: inputs.nombre,
      telefono: inputs.telefono,
      telefono2: inputs.telefono2,
      email: inputs.email,
    };

    const valida = validaContenido(body, "");
    if (valida.status) {
      fetchdata(body);
    } else {
      setNotificacionAcc(valida.alert.accion);
      setNotificacionMsg(valida.alert.message);
    }
  };

  return (
    <Box
      className="form"
      sx={{
        position: "absolute",
        top: "0",
        left: "40px",
        zIndex: "999",
        padding: "0px 16px 16px 0px",
        height: "100%",
        width: "calc(100% - 80px);",
      }}
    >
      <Grid container sx={{ height: "100%" }}>
        <Grid
          item
          xs={6}
          sx={{ height: "100%", display: { xs: "none", md: "block" } }}
        >
          &nbsp;
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              background: "#ffffffdb",
              margin: "16px",
              marginTop: "80px",
              padding: "0  16px 16px 0",
              flexDirection: "column",
              width: { xs: "100%", md: "60%", lg: "40%" },
              borderRadius: "8px",
              textAlign: "center",
              color: "#fff",
            }}
          >
            {inputs.activo === false ? (
              <>
                <Grid item>
                  <Typography variant="h5" sx={{ color: "black" }}>
                    Solicita más información
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    size="small"
                    id="nombre"
                    label="Nombre"
                    variant="filled"
                    fullWidth
                    value={inputs.nombre}
                    onChange={handleChange("nombre")}
                    disabled={inputs.activo}
                    sx={{ "& .MuiFormLabel-root:": { color: "#fff" } }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    size="small"
                    id="telefono"
                    label="telefono"
                    variant="filled"
                    type="number"
                    value={inputs.telefono}
                    onChange={handleChange("telefono")}
                    disabled={inputs.activo}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    size="small"
                    id="telefono2"
                    label="telefono (Opcional)"
                    variant="filled"
                    type="number"
                    value={inputs.telefono2}
                    onChange={handleChange("telefono2")}
                    disabled={inputs.activo}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    size="small"
                    id="email"
                    label="E-mail"
                    variant="filled"
                    value={inputs.email}
                    onChange={handleChange("email")}
                    disabled={inputs.activo}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Button variant="outlined" onClick={handleClickSend}>
                    Enviar
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item sx={{ color: "black" }}>
                  Enviardo informacion...
                </Grid>
                <Grid item>
                  <Stack
                    justifyContent="center"
                    sx={{ color: "grey.500" }}
                    spacing={2}
                    direction="row"
                  >
                    <CircularProgress color="success" />
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
export { Formulario };
export default Formulario;
