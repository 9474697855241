import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as ReactLogo } from "../svg/logo-mi-esperanza-completo.svg";

function Somos() {
  const theme = useTheme();
  return (
    <Box component="section">
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            "& svg": {
              padding: "5%",
              maxWidth: "500px",
            },
            textAlign: "center",
            "& .title": { fill: theme.palette.common.black },
          }}
        >
          <ReactLogo />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "2%",
          }}
        >
          <Box
            sx={{
              marginBottom: theme.spacing(4),
              textAlign: "center",
              display: { xs: "none", md: "block" },
            }}
          >
            <Typography
              variant="h3"
              component="span"
              sx={{ textTransform: "uppercase" }}
            >
              Carniceria
            </Typography>
            <Typography
              variant="h3"
              component="span"
              sx={{ textTransform: "uppercase", fontWeight: "900" }}
            >
              {" "}
              Mi Esperanza
            </Typography>
          </Box>
          <Typography variant="body1" sx={{ padding: theme.spacing(1) }}>
            Somos una empresa dedicada a la distribución de carnes de la mas
            alta calidad tanto res, puerco, borrego atendiendo según la
            necesidad del cliente, ya sea por piezas o en cortes especiales.
          </Typography>
          <Typography variant="body1" sx={{ padding: theme.spacing(1) }}>
            Ya sea para preparar una deliciosa comida familiar o un banquete
            privado, o bien para un comedor comercial o restaurante.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Somos;
