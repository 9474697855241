import React, { useContext } from "react";

export type typemessage = "error" | "info" | "success" | "warning";

export interface AppProps {
  notificacionMsg: string;
  setNotificacionMsg: (notificacionMsg: string) => void;
  notificacionAcc: typemessage;
  setNotificacionAcc: (notificacionAcc: typemessage) => void;
}

const Appcontext = React.createContext<AppProps>({
  notificacionMsg: "",
  setNotificacionMsg: (notificacionMsg: string) => {},
  notificacionAcc: "success",
  setNotificacionAcc: (notificacionAcc: typemessage) => {},
});

export interface PropsAppProviderProps {
  children: React.ReactNode;
}

const AppProvider = ({ children }: PropsAppProviderProps) => {
  const [notificacionMsg, setNotificacionMsg] = React.useState<string>("");
  const [notificacionAcc, setNotificacionAcc] =
    React.useState<typemessage>("success");

  return (
    <Appcontext.Provider
      value={{
        notificacionMsg,
        setNotificacionMsg,
        notificacionAcc,
        setNotificacionAcc,
      }}
    >
      {children}
    </Appcontext.Provider>
  );
};

export const useAppProvider = () => useContext(Appcontext);

export default AppProvider;
