import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  LinearProgress,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import { FloatingWhatsApp } from "react-floating-whatsapp";

import { IconBreadcrumbs, ScrollTop, DivSanitized } from "../components";
import { usePeticiones } from "../useComplements";

interface receta {
  receta_id: string;
  status_id: string;
  registro: string;
  titulo: string;
  contenido: string;
  imagen: string;
}

function PageSReceta() {
  const { peticionAsync } = usePeticiones();
  const { useReceta, useTitulo } = useParams();
  const navigate = useNavigate();

  let iconBread: any[] = [];

  function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    navigate(0);
  }

  iconBread = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      Home
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/recetas/"
      onClick={handleClick}
    >
      recetas
    </Link>,
    <Typography
      key="3"
      color="text.primary"
      component="div"
      sx={{ color: "unset", fontSize: "unset" }}
    >
      <strong>{useTitulo}</strong>
    </Typography>,
  ];
  const datos = {
    receta_id: "",
    status_id: "",
    registro: "",
    titulo: "",
    contenido: "",
    imagen: "",
  };

  const [receta, setReceta] = React.useState<receta>(datos);

  const [mount, setMount] = React.useState<boolean>(true);

  React.useEffect(() => {
    const body = { id: useReceta };

    const fetchdata = async (info: any) => {
      const result: any = await peticionAsync({
        accion: "admin/json-cat/recetas/show",
        formData: info,
      });

      if (result.success) {
        setReceta(result.data[0]);
      }
    };

    if (!mount) {
      setMount(true);
      fetchdata(body);
    }
  }, [mount, peticionAsync, useReceta]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setMount(false);
    }, 10);
    return () => clearTimeout(timer);
  }, []);

  console.log(receta);
  return (
    <>
      <Container
        id="back-to-top-anchor"
        sx={{
          marginTop: { xs: "150px", md: "220px" },

          "& .styles-module_whatsappButton__tVits": {
            right: ".9rem",
          },
        }}
      >
        {mount === false ? (
          <Box>
            <LinearProgress />
          </Box>
        ) : (
          <>
            <ScrollTop />
            <IconBreadcrumbs breadcrumbs={iconBread} />

            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" component="h1" align="center">
                  {receta.titulo}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    "& big": {
                      fontSize: "unset",
                    },
                    "& img": {
                      width: "100%",
                    },
                  }}
                >
                  <DivSanitized text={receta.contenido} />
                </Typography>
              </Grid>
            </Grid>
            <FloatingWhatsApp
              phoneNumber="+526693335323"
              accountName="Carnes Mi Esperanza"
              chatMessage="¡Hola! 🤝 ¿Cómo podemos ayudar?"
              statusMessage="Normalmente responde en 20 minutos."
              avatar={`${process.env.PUBLIC_URL}/icons/favicon-96x96.png`}
              allowEsc
              allowClickAway
              notification
              notificationSound
            />
          </>
        )}
      </Container>
    </>
  );
}
export { PageSReceta };
export default PageSReceta;
