import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { FloatingWhatsApp } from "react-floating-whatsapp";

import { ScrollTop } from "../components";
import Footer from "../components/Footer";
function PageBolsaTrabajo() {
  return (
    <Container
      id="back-to-top-anchor"
      sx={{
        marginTop: "230px",
        minHeight: "calc(100vh - 240px)",
        "& .styles-module_whatsappButton__tVits": {
          right: ".9rem",
        },
        "& .styles-module_floatingWhatsapp__yWF5K": {
          height: "unset",
        },
      }}
    >
      <ScrollTop />

      <Grid container sx={{ justifyContent: "center", margin: "32px 0" }}>
        <Grid item xs={12} md={6}>
          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: "#2c2a2a",
              padding: "32px",
              borderRadius: "8px",
              color: "white",
            }}
          >
            <Typography variant="h4" component="h1" align="center">
              BOLSA DE TRABAJO
            </Typography>

            <Typography
              variant="h6"
              component="h3"
              align="center"
              sx={{ marginTop: "16px", color: "#ae0730", fontWeight: "900" }}
            >
              REPARTIDOR
            </Typography>
            <Typography
              variant="body1"
              component="p"
              align="center"
              sx={{
                marginTop: "32px",
                "& b": { fontWeight: "900", color: "yellow" },
              }}
            >
              <b>REQUISITOS:</b> LICENCIA DE MANEJO, PUNTUAL, RESPONSABLE CON O
              SIN EXPERIENCIA.
            </Typography>

            <Typography
              variant="h6"
              component="h3"
              align="center"
              sx={{
                marginTop: "16px",
                color: "#ae0730",
                fontWeight: "900",
              }}
            >
              AUXILIAR DE BODEGA
            </Typography>

            <Typography
              variant="body1"
              component="p"
              align="center"
              sx={{
                marginTop: "32px",
                "& b": { fontWeight: "900", color: "yellow" },
              }}
            >
              <b>REQUISITOS:</b> RESPONSABLE, PUNTUAL, ACTIVO CON O SIN
              EXPERIENCIA.
            </Typography>

            <Typography
              variant="body1"
              component="p"
              align="center"
              sx={{
                marginTop: "32px",
                "& b": { fontWeight: "900", color: "yellow" },
              }}
            >
              <b>INTERESADOS:</b> LLEVAR SOLICITUD ELABORADA A CARNICERIA MI
              ESPERANZA UBICADA EN CALLE 13 DE ABRIL, LOCAL A22 INTERIOR DEL
              MERCADO DE LA JUÁREZ.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Footer />

      <FloatingWhatsApp
        phoneNumber="+526693335323"
        accountName="Carnes Mi Esperanza"
        chatMessage="¡Hola! 🤝 ¿Cómo podemos ayudar?"
        statusMessage="Normalmente responde en 20 minutos."
        avatar={`${process.env.PUBLIC_URL}/icons/favicon-96x96.png`}
        allowEsc
        allowClickAway
        notification
        notificationSound
      />
    </Container>
  );
}
export { PageBolsaTrabajo };
export default PageBolsaTrabajo;
