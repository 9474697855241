import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import { red } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";

function MapaUbicacion() {
  const theme = useTheme();

  const [mount, setMount] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!mount) {
      setMount(true);
    }
  }, [mount]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          component="h3"
          sx={{
            mt: theme.spacing(5),
            color: red[500],
            fontWeight: "bold",
            fontSize: { xs: "1.75rem", md: "2.2rem" },
          }}
          align="center"
        >
          PASATE POR EL LOCAL
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={{
            mb: theme.spacing(2),
            mt: 1,
            color: theme.palette.primary.main,
          }}
          variant="body1"
          align="center"
        >
          13 de Abril 20, Benito Juárez, 82180 Mazatlán, Sin.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ md: { padding: theme.spacing(1, 4) } }}>
        <Box
          className="google-map-code"
          sx={{ "& .ubicacion": { height: { xs: "350px", md: "390px" } } }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d458.2960854928494!2d-106.40587210629204!3d23.22966718372656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7b9a68e73c80cf0d!2sCarnicer%C3%ADa%20Mi%20Esperanza!5e0!3m2!1ses-419!2smx!4v1646891082978!5m2!1ses-419!2smx"
            width="100%"
            height="390"
            aria-hidden="false"
            title="Ubicacion"
            className="ubicacion"
          />
        </Box>
      </Grid>
    </Grid>
  );
}
MapaUbicacion.defaultProps = {};

export default MapaUbicacion;
