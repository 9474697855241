import * as React from "react";
import {
  FormControl,
  Input,
  InputLabel,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  Typography,
  DialogActions,
  DialogContent,
} from "@mui/material";

import {
  AddCircle as AddCircleIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  AddPhotoAlternateOutlined as AddPhotoAlternateOutlinedIcon,
  ImageOutlined as ImageOutlinedIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material/";
import { useTheme } from "@mui/material/styles";

import { useAppProvider } from "../../../providers/Appcontext";
import { ModLists } from "../";

import { FileUploader } from "react-drag-drop-files";
import { usePeticiones, useValidaForm } from "../../../useComplements";

interface dataState {
  producto_id: string;
  producto: string;
}

interface inputsState {
  producto: string;
  descripcion: string;
  beneficios: string;
  UPCCode: string;
  BarrCode: string;
  categoria_id: string;
  iva: string;
  precio: string;
  imagen: string;
  activo: boolean;
}

interface modifyState {
  list: boolean;
  edit: boolean;
  id: number | null | string;
}

interface listCategorias {
  id: string;
  categoria: string;
}

interface ModProductosProps {}

function ModProductos(props: ModProductosProps) {
  const theme = useTheme();
  const { setNotificacionMsg, setNotificacionAcc } = useAppProvider();
  const { peticionAsync, peticionEspecial } = usePeticiones();
  const { validaContenido } = useValidaForm();
  const fileTypes = ["JPG", "PNG"];

  const iniDatos = () => {
    const datos: inputsState = {
      producto: "",
      descripcion: "",
      beneficios: "",
      UPCCode: "",
      BarrCode: "",
      categoria_id: "",
      iva: "",
      precio: "",
      imagen: "",
      activo: false,
    };
    return datos;
  };

  const [categorias, setCategorias] = React.useState<listCategorias[]>([]);
  const [cloudUpload, setCloudUpload] = React.useState<Boolean>(true);

  const [mount, setMount] = React.useState<boolean>(true);
  const [data, setData] = React.useState<dataState[]>([]);
  const [inputs, setInputs] = React.useState<inputsState>(iniDatos());
  const routes: string = "admin/json-cat/productos";
  const columnsHead = [
    {
      field: "categoria",
      headerName: "Categoria",
      width: 150,
    },
    {
      field: "producto",
      headerName: "Producto",
      minWidth: 150,
      flex: 1,
    },
  ];

  const [modify, setModify] = React.useState<modifyState>({
    list: true,
    edit: false,
    id: null,
  });

  // -------------------------------------
  // Control de Acciones Grabado DataBase
  // -------------------------------------

  const fetchUpImagens = (info: any) => {
    const body = new FormData();

    if (info) {
      body.append("file_tmp", info);
      peticionEspecial({
        accion: `${routes}/upImagen`,
        formData: body,
      }).then(
        (datos: any) => {
          if (datos.success) {
            setInputs({ ...inputs, imagen: datos.data.imagen_url });
          }
          setCloudUpload(true);
        },
        (error: any) => {
          setCloudUpload(true);
        }
      );
    }
  };

  const handleClickAdd = () => {
    const body = {
      producto: inputs.producto.toLocaleUpperCase("en-US"),
      descripcion: inputs.descripcion.toLocaleUpperCase("en-US"),
      beneficios: inputs.beneficios.toLocaleUpperCase("en-US"),
      UPCCode: inputs.UPCCode.toLocaleUpperCase("en-US"),
      BarrCode: inputs.BarrCode.toLocaleUpperCase("en-US"),
      categoria_id: inputs.categoria_id,
      iva: inputs.iva,
      precio: inputs.precio,
      imagen: inputs.imagen,
    };

    const fetchAdd = async (info: any) => {
      const result: any = await peticionAsync({
        accion: `${routes}/add`,
        formData: info,
      });

      if (result.success) {
        setMount(false);
        setNotificacionAcc("success");
        setNotificacionMsg("Registro agreagado exitosa.");
        setInputs(iniDatos());
        setModify({ list: true, edit: false, id: null });
      } else {
        setNotificacionAcc("warning");
        setNotificacionMsg("Registro agreagado fallido.");
        setInputs({ ...inputs, activo: false });
      }
    };

    const valida = validaContenido(body, modify.edit ? "edit" : "");
    if (valida.status) {
      setInputs({ ...inputs, activo: true });
      fetchAdd(body);
    } else {
      setNotificacionAcc(valida.alert.accion);
      setNotificacionMsg(valida.alert.message);
      setInputs({ ...inputs, activo: false });
    }
  };

  const handleClickEdit = (id: any) => {
    setModify({ list: false, edit: true, id: id });

    const reesult: any = data.filter((a: any) => a.id === id)[0];

    const body = {
      producto: reesult.producto.toLocaleUpperCase("en-US"),
      descripcion: reesult.descripcion.toLocaleUpperCase("en-US"),
      beneficios: reesult.beneficios.toLocaleUpperCase("en-US"),
      UPCCode: reesult.UPCCode.toLocaleUpperCase("en-US"),
      BarrCode: reesult.BarrCode.toLocaleUpperCase("en-US"),
      categoria_id: reesult.categoria_id,
      iva: reesult.iva,
      precio: reesult.precio,
      imagen: reesult.imagen,
      activo: false,
    };
    setInputs(body);
  };

  const handleClickDelete = (id: any) => {
    const body = { id: id };
    setInputs({ ...inputs, activo: true });

    const fetchDelete = async (info: any) => {
      const result: any = await peticionAsync({
        accion: `${routes}/delete`,
        formData: info,
      });

      if (result.success) {
        setMount(false);
        setNotificacionAcc("success");
        setNotificacionMsg("Eliminación correctamente.");
        setInputs(iniDatos());
        setModify({ list: true, edit: false, id: null });
      } else {
        setNotificacionAcc("error");
        setNotificacionMsg("Eliminación no realizada.");
        setInputs({ ...inputs, activo: false });
      }
    };
    fetchDelete(body);
  };

  const handleClickGrabar = (key: number | null | string) => {
    const body = {
      id: key,
      producto: inputs.producto.toLocaleUpperCase("en-US"),
      descripcion: inputs.descripcion.toLocaleUpperCase("en-US"),
      beneficios: inputs.beneficios.toLocaleUpperCase("en-US"),
      UPCCode: inputs.UPCCode.toLocaleUpperCase("en-US"),
      BarrCode: inputs.BarrCode.toLocaleUpperCase("en-US"),
      categoria_id: inputs.categoria_id,
      iva: inputs.iva,
      precio: inputs.precio,
      imagen: inputs.imagen.split("/")[inputs.imagen.split("/").length - 1],
    };

    setInputs({ ...inputs, activo: true });

    const fetchUpdate = async (info: any) => {
      const result: any = await peticionAsync({
        accion: `${routes}/update`,
        formData: info,
      });

      if (result.success) {
        setMount(false);
        setNotificacionAcc("success");
        setNotificacionMsg("Actualización exitosa.");
        setInputs(iniDatos());
        setModify({ list: true, edit: false, id: null });
      } else {
        setNotificacionAcc("success");
        setNotificacionMsg("Actualización fallida.");
        setInputs({ ...inputs, activo: false });
      }
    };

    const valida = validaContenido(body, modify.edit ? "edit" : "");

    if (valida.status) {
      setInputs({ ...inputs, activo: true });
      fetchUpdate(body);
    } else {
      setNotificacionAcc(valida.alert.accion);
      setNotificacionMsg(valida.alert.message);
      setInputs({ ...inputs, activo: false });
    }
  };

  // -------------------------------------
  // Fin de Acciones Grabado DataBase
  // -------------------------------------

  const handleChange =
    (prop: keyof inputsState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputs({ ...inputs, [prop]: event.target.value });
    };

  const handleChangeImagen = (file: any) => {
    if (file) {
      setCloudUpload(false);
      fetchUpImagens(file);
    }
  };

  const handleChangecategoria = (event: SelectChangeEvent) => {
    setInputs({ ...inputs, categoria_id: event.target.value });
  };

  const handleChangeActiveAdd = () => {
    setModify({ list: false, edit: false, id: null });
  };

  const handleClickEditCancel = () => {
    setInputs(iniDatos());
    setModify({ list: true, edit: false, id: null });
  };

  React.useEffect(() => {
    const body = {};

    const fetchdata = async (info: any) => {
      const result: any = await peticionAsync({
        accion: `${routes}/list`,
        formData: info,
      });
      if (result.success) {
        setData(result.data.data);
        setCategorias(result.data.listCategorias);
      }
    };

    if (!mount) {
      setMount(true);
      fetchdata(body);
    }
  }, [mount, peticionAsync]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setMount(false);
    }, 10);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {modify.list ? (
        <>
          {data.length > 0 ? (
            <>
              <ModLists
                rowsList={data}
                columnsHead={columnsHead}
                selectRow={handleClickEdit}
                deleteRow={handleClickDelete}
              />
              <DialogActions>
                <Button
                  onClick={handleChangeActiveAdd}
                  variant="contained"
                  size="small"
                  endIcon={<AddCircleIcon />}
                >
                  Agregar
                </Button>
              </DialogActions>
            </>
          ) : null}
        </>
      ) : (
        <>
          <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: { sm: "flex" },
                flexDirection: "column",
                height: "400px",
                maxHeight: "600px",
                width: "100%",
                "& input": { textTransform: "uppercase" },
              }}
            >
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="input-producto">Producto</InputLabel>
                <Input
                  autoComplete="off"
                  id="input-producto"
                  type="text"
                  value={inputs.producto}
                  onChange={handleChange("producto")}
                  fullWidth
                  disabled={inputs.activo}
                />
              </FormControl>
              <FormControl
                variant="standard"
                sx={{
                  mb: 1,
                  mr: 1,
                  width: "100%",
                  marginLeft: "8px",
                }}
              >
                <InputLabel id="input-producto-categorias-label">
                  categorias
                </InputLabel>
                <Select
                  labelId="input-producto-categorias-label"
                  id="input-producto-categoria"
                  value={inputs.categoria_id}
                  onChange={handleChangecategoria}
                  label="Categoría"
                >
                  <MenuItem value="">None</MenuItem>
                  {categorias
                    ? categorias.map(
                        (itemcategorias: listCategorias, index: number) => (
                          <MenuItem
                            selected
                            key={`list${index.toString()}-categoria`}
                            value={itemcategorias.id}
                            sx={{ textTransform: "uppercase" }}
                          >
                            {itemcategorias.categoria}
                          </MenuItem>
                        )
                      )
                    : null}
                </Select>
              </FormControl>

              <Box sx={{ display: { sm: "flex" } }}>
                <FormControl
                  sx={{ m: 1, width: { xs: "100%", sm: "50%" } }}
                  variant="standard"
                >
                  <InputLabel htmlFor="input-producto-beneficios">
                    Beneficios
                  </InputLabel>
                  <Input
                    autoComplete="off"
                    id="input-producto-beneficios"
                    type="text"
                    value={inputs.beneficios}
                    onChange={handleChange("beneficios")}
                    fullWidth
                    disabled={inputs.activo}
                  />
                </FormControl>
                <FormControl
                  sx={{ m: 1, width: { xs: "100%", sm: "50%" } }}
                  variant="standard"
                >
                  <InputLabel htmlFor="input-producto-descripcion">
                    Descripción
                  </InputLabel>
                  <Input
                    autoComplete="off"
                    id="input-producto-descripcion"
                    type="text"
                    value={inputs.descripcion}
                    onChange={handleChange("descripcion")}
                    fullWidth
                    disabled={inputs.activo}
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: { sm: "flex" } }}>
                <FormControl
                  sx={{ m: 1, width: { xs: "100%", sm: "50%" } }}
                  variant="standard"
                >
                  <InputLabel htmlFor="input-producto-UPC">
                    Código UPC
                  </InputLabel>
                  <Input
                    autoComplete="off"
                    id="input-producto-UPC"
                    type="text"
                    value={inputs.UPCCode}
                    onChange={handleChange("UPCCode")}
                    fullWidth
                    disabled={inputs.activo}
                  />
                </FormControl>

                <FormControl
                  sx={{ m: 1, width: { xs: "100%", sm: "50%" } }}
                  variant="standard"
                >
                  <InputLabel htmlFor="input-producto-Barr">
                    Código Barras
                  </InputLabel>
                  <Input
                    autoComplete="off"
                    id="input-producto-Barr"
                    type="text"
                    value={inputs.BarrCode}
                    onChange={handleChange("BarrCode")}
                    fullWidth
                    disabled={inputs.activo}
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: { sm: "flex" } }}>
                <FormControl
                  sx={{ m: 1, width: { xs: "100%", sm: "50%" } }}
                  variant="standard"
                >
                  <InputLabel htmlFor="input-producto-precio">
                    Precio
                  </InputLabel>
                  <Input
                    autoComplete="off"
                    id="input-producto-precio"
                    type="text"
                    value={inputs.precio}
                    onChange={handleChange("precio")}
                    fullWidth
                    disabled={inputs.activo}
                  />
                </FormControl>
                <FormControl
                  sx={{ m: 1, width: { xs: "100%", sm: "50%" } }}
                  variant="standard"
                >
                  <InputLabel htmlFor="input-producto-iva">IVA</InputLabel>
                  <Input
                    autoComplete="off"
                    id="input-producto-iva"
                    type="text"
                    value={inputs.iva}
                    onChange={handleChange("iva")}
                    fullWidth
                    disabled={inputs.activo}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: { sm: "flex", justifyContent: "space-between" },
                }}
              >
                <FormControl
                  variant="standard"
                  sx={{
                    width: { xs: "25%" },
                    justifyContent: "space-between",
                  }}
                >
                  <FileUploader
                    label="Subir imagen"
                    hoverTitle="Soltar aquí"
                    handleChange={handleChangeImagen}
                    name="file"
                    types={fileTypes}
                    disabled={inputs.activo && cloudUpload}
                  >
                    <Box
                      sx={{
                        border: "2px dashed rgb(6, 88, 194)",
                        padding: theme.spacing(1, 2),
                        borderRadius: theme.shape.borderRadius,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        component="div"
                        variant="subtitle2"
                        align="center"
                      >
                        {cloudUpload === true ? (
                          <>
                            <AddPhotoAlternateOutlinedIcon fontSize="large" />
                            <span>
                              <br />
                              Soltar Imagen
                            </span>
                          </>
                        ) : (
                          <>
                            <CloudUploadIcon fontSize="large" />
                            <span>
                              <br />
                              Subiendo Imagen
                            </span>
                          </>
                        )}
                      </Typography>
                    </Box>
                  </FileUploader>
                </FormControl>
                <FormControl />
                <FormControl
                  sx={{
                    border: "2px solid rgb(6, 88, 194)",
                    padding: theme.spacing(1, 2),
                    borderRadius: theme.shape.borderRadius,
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "80px",
                    marginBottom: "5px",
                    flexDirection: "column",
                    alignItems: "center",
                    "& img": {
                      width: "100%",
                    },
                  }}
                >
                  {inputs.imagen ? (
                    <img src={inputs.imagen} alt={inputs.imagen} />
                  ) : (
                    <>
                      <ImageOutlinedIcon fontSize="large" />
                      <span>Imagen</span>
                    </>
                  )}
                </FormControl>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            {modify.edit ? (
              <>
                <Button
                  onClick={() => handleClickGrabar(modify.id)}
                  variant="contained"
                  endIcon={<CheckCircleIcon />}
                  sx={{ mr: 1 }}
                >
                  Grabar
                </Button>
                <Button
                  onClick={handleClickEditCancel}
                  variant="outlined"
                  endIcon={<CancelIcon />}
                >
                  Cancelar
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={handleClickEditCancel}
                  variant="outlined"
                  endIcon={<CancelIcon />}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={handleClickAdd}
                  variant="contained"
                  endIcon={<AddCircleIcon />}
                >
                  Agregar
                </Button>
              </>
            )}
          </DialogActions>
        </>
      )}
    </>
  );
}

export { ModProductos };
export default ModProductos;
