import * as React from "react";
import { Snackbar } from "@mui/material";
import Slide, { SlideProps } from "@mui/material/Slide";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

import { v4 as uuidv4 } from "uuid";

import { useAppProvider } from "../providers/Appcontext";

function ToastBar() {
  const { notificacionMsg, setNotificacionMsg, notificacionAcc } =
    useAppProvider();

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = () => {
    setNotificacionMsg("");
  };

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setNotificacionMsg("");
  };

  const TransitionLeft = (props: SlideProps) => {
    return <Slide {...props} direction="left" />;
  };

  const alertas = (
    <Alert
      onClose={handleCloseAlert}
      severity={notificacionAcc}
      sx={{ width: "100%" }}
    >
      {notificacionMsg}
    </Alert>
  );

  return notificacionMsg ? (
    <Snackbar
      key={uuidv4()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      TransitionComponent={TransitionLeft}
      onClose={handleClose}
      open={notificacionMsg !== ""}
      autoHideDuration={4000}
      message={alertas}
    >
      {alertas}
    </Snackbar>
  ) : null;
}

export { ToastBar };
export default ToastBar;
