import * as React from "react";
import { Box, DialogContent } from "@mui/material/";

import { blue } from "@mui/material/colors";
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridActionsCellItem,
} from "@mui/x-data-grid";

import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";

import { Loading } from "../";

interface ModListsProps {
  rowsList: any[];
  selectRow: (id: any) => void;
  deleteRow: (id: any) => void;
  columnsHead: GridColDef[];
}

function ModLists(props: ModListsProps) {
  const { rowsList, selectRow, deleteRow, columnsHead } = props;

  const editaRow = React.useCallback(
    (id: GridRowId) => () => {
      selectRow(id);
    },
    [selectRow]
  );

  const rowDelete = React.useCallback(
    (id: GridRowId) => () => {
      deleteRow(id);
    },
    [deleteRow]
  );

  const columns = columnsHead;
  if (columns.filter((a) => a.field === "actions").length === 0) {
    columns.push({
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Delete"
          onClick={editaRow(params.id)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={rowDelete(params.id)}
        />,
      ],
    });
  }

  return rowsList.length <= 0 ? (
    <Loading />
  ) : (
    <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          height: "420px",
          maxHeight: "600px",
          width: "100%",
          marginTop: "10px",
        }}
      >
        <DataGrid
          disableColumnFilter={true}
          disableColumnMenu={true}
          disableColumnSelector={true}
          disableDensitySelector={true}
          rows={rowsList}
          columns={columns}
          density="compact"
          sx={{
            height: "100%",
            "& .MuiDataGrid-columnHeaders": {
              bgcolor: blue[200],
              color: blue[900],
            },

            "& .MuiDataGrid-columnHeader:focus,& .MuiDataGrid-cell:focus": {
              outline: "unset",
            },
            "& .MuiDataGrid-cell": {
              cursor: "default",
              webkitTouchCallout: "none",
              webkitUserSelect: "none",
              khtmlUserSelect: "none",
              mozUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
              textTransform: "uppercase",
            },

            "& .MuiToolbar-root .MuiTablePagination-selectLabel,& .MuiToolbar-root .MuiInputBase-root":
              {
                display: "none",
              },
            "& .MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount": {
              visibility: " hidden !important;",
            },

            "& .MuiDataGrid-virtualScroller": {
              "&::-webkit-scrollbar": {
                width: "8px",
                height: "8px",
              },
              "&::-webkit-scrollbar-track": {
                borderRadius: "8px",
                backgroundColor: "#e7e7e7",
                border: "1px solid #cacaca",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "18px",
                backgroundColor: "#0000008a",
              },
            },
          }}
        />
      </Box>
    </DialogContent>
  );
}

export { ModLists };
export default ModLists;
