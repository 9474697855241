import * as React from "react";
import { Box, Grid } from "@mui/material";
import Slider from "react-carousel-responsive";

import Formulario from "./Formulario";

// import { AnimationOnScroll } from 'react-animation-on-scroll';
// import 'animate.css/animate.min.css';
import "./MenuSlider.css";

const useStyles = {
  display: "block",
  position: "relative",
  "& .imagen": {
    position: "absolute",
    display: "flex",
    height: "100%",
    width: "100%",
    top: "0",
  },

  "& .shadow": {
    position: "absolute",
    display: "flex",
    height: "100%",
    width: "100%",
    backgroundColor: "#0000004f",
    top: "0",
  },
  "& .infoText": {
    position: "absolute",
    display: "flex",
    height: "100%",
    width: "100%",
    top: "0",
  },

  "& .slide .img1": {
    backgroundImage:
      "url(/imagenes/slide/SLIDE_01.webp), url(/imagenes/slide/SLIDE_01.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
  "& .slide .img2": {
    backgroundImage:
      "url(/imagenes/slide/SLIDE_02.webp), url(/imagenes/slide/SLIDE_02.jpg) ",
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
  "& .slide .img3": {
    backgroundImage:
      " url(/imagenes/slide/SLIDE_03.webp) , url(/imagenes/slide/SLIDE_03.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
  "& .slide .img4": {
    backgroundImage:
      " url(/imagenes/slide/SLIDE_02.webp), url(/imagenes/slide/SLIDE_02.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
  "& .slide .img5": {
    backgroundImage: "url(/imagenes/slide/SLIDE_04.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
};

function MenuSlider() {
  const [mount, setMount] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!mount) {
      setMount(true);
    }
  }, [mount]);

  return (
    <Box sx={useStyles}>
      <Slider autoplaySpeed={4000}>
        <div className="slide">
          <img src="/imagenes/slide/transparent.png" alt="" />
          <div className="informa">
            <Box className="imagen img1" />
            <Box className="shadow" />
            <Box className="infoText">
              <Grid container sx={{ height: "100%" }}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    height: "100%",
                    justifyContent: "center",
                    display: { xs: "none", md: "flex" },
                    flexDirection: "column",
                    marginLeft: "40px",
                  }}
                >
                  <h6>La mejor carne</h6>
                  <h1>CARNE DE RES</h1>
                  <p>
                    Para relizar sus mejores platillos. Para el hogar, eventos y
                    negocios.
                  </p>
                </Grid>
                <Grid item xs={6}>
                  &nbsp;
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
        <div className="slide">
          <img src="/imagenes/slide/transparent.png" alt="" />
          <div className="informa">
            <Box className="imagen img2" />
            <Box className="shadow" />
            <Box className="infoText">
              <Grid container sx={{ height: "100%" }}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    height: "100%",
                    justifyContent: "center",
                    display: { xs: "none", md: "flex" },
                    flexDirection: "column",
                    marginLeft: "40px",
                  }}
                >
                  <h6>La mejor calidad</h6>
                  <h2>CARNE DE CERDO</h2>
                  <p>Nos distingue nuestra calidad y sercio.</p>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
        <div className="slide">
          <img src="/imagenes/slide/transparent.png" alt="" />
          <div className="informa">
            <Box className="imagen img3" />
            <Box className="shadow" />
            <Box className="infoText">
              <Grid container sx={{ height: "100%" }}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    height: "100%",
                    justifyContent: "center",
                    display: { xs: "none", md: "flex" },
                    flexDirection: "column",
                    marginLeft: "40px",
                  }}
                >
                  <h6>Disfrute del mejor sabor</h6>
                  <h2>CORTES AMERICANOS</h2>
                  <p>
                    Estos son considerados de la más alta calidad y se
                    distinguen por su gran suavidad, jugosidad y excelente
                    textura.
                  </p>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </Slider>
      <Formulario />
    </Box>
  );
}

export { MenuSlider };
export default MenuSlider;

//
