import React from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as Pollo } from "../svg/iconPollo2.svg";
import { ReactComponent as Puerco } from "../svg/iconPuerco2.svg";
import { ReactComponent as Vaca } from "../svg/iconVaca2.svg";
import { ReactComponent as Borrego } from "../svg/iconCabrito2.svg";

interface Lista {
  img: string;
  icon: any;
  Title: string;
  Items: string;
  link: string;
}

function ListCategorias() {
  const theme = useTheme();

  const ListCat: Lista[] = [
    {
      img: `${process.env.PUBLIC_URL}/imagenes/Carne-de-Res.jpg`,
      icon: <Vaca />,
      Title: "Res",
      Items: "20",
      link: "/producto-categoria/res",
    },
    {
      img: `${process.env.PUBLIC_URL}/imagenes/Carne-de-Puerco.jpg`,
      icon: <Puerco />,
      Title: "Cerdo",
      Items: "30",
      link: "/producto-categoria/cerdo/",
    },
    {
      img: `${process.env.PUBLIC_URL}/imagenes/Carne-de-Pollo.jpg`,
      icon: <Pollo />,
      Title: "Pollo",
      Items: "40",
      link: "/producto-categoria/pollo/",
    },
    {
      img: `${process.env.PUBLIC_URL}/imagenes/Carne-de-ternera.jpg`,
      icon: <Borrego />,
      Title: "Cordero",
      Items: "50",
      link: "/producto-categoria/cordero/",
    },
  ];

  return (
    <Box component="section">
      <Grid container>
        {ListCat.map((item: Lista, index) => (
          <Grid
            key={`list-cat-${index + 1}`}
            item
            xs={12}
            sm={6}
            lg={3}
            sx={{ overflow: "hidden" }}
          >
            <Box
              sx={{
                position: "relative",
                height: "100%",
                "& img": {
                  width: "100%",
                  height: "100%",
                  transition: "transform 5s , height 2s",
                },
                "&:hover img": { transform: "scale(1.4)" },
              }}
            >
              <Link href={item.link}>
                <picture>
                  <img src={item.img} alt="IMG" />
                </picture>

                <Box
                  sx={{
                    position: "absolute",
                    top: "0",
                    width: "86%",
                    height: "100%",
                    padding: "7%",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    backgroundColor: "#00000078",
                    "& svg": {
                      height: "60%",
                    },
                  }}
                >
                  {item.icon}
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      fontWeight: "900",
                      color: "#ffff",
                      margin: theme.spacing(2, 0),
                    }}
                  >
                    {item.Title} ( {item.Items} )
                  </Typography>
                </Box>
              </Link>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default ListCategorias;
