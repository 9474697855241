import React from "react";
import {
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";

interface ListOrdenItem {
  id: string;
  etiqueta: string;
  value: string;
  activo: boolean;
}

export interface FilterBarrProps {
  filter: [string, React.Dispatch<React.SetStateAction<string>>];
  message?: string | undefined;
}

function FilterBarr({ filter, message }: FilterBarrProps) {
  const theme = useTheme();
  const [filterOrden, setFilterOrden] = filter;

  const list = [
    {
      id: `NombreAZ`,
      etiqueta: "Nombre:de la [A-Z]",
      value: "NombreAZ",
      activo: true,
    },
    {
      id: `NombreZA`,
      etiqueta: "Nombre:de la [Z-A]",
      value: "NombreZA",
      activo: true,
    },
  ];

  const handleChange = (event: SelectChangeEvent) => {
    setFilterOrden(event.target.value as string);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        flexDirection: "column",
        display: { xs: "flex" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex" },
            justifyContent: "center",
          }}
        ></Box>
        <FormControl
          sx={{
            m: 1,
            minWidth: "150px",
            fontSize: "11px",
            borderRadius: theme.spacing(1),
            background: theme.palette.grey[100],
            "& .MuiInputBase-root, & .MuiButtonBase-root": {
              fontSize: "inherit",
            },
          }}
          size="small"
        >
          <InputLabel
            id="demo-select-small"
            sx={{
              fontSize: "inherit",
              "&.MuiInputLabel-shrink": { display: "none" },
            }}
          >
            Ordenar por
          </InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={filterOrden}
            onChange={handleChange}
            className="Guillermo"
            sx={{
              fontSize: "11px",
            }}
          >
            {list?.map((item: ListOrdenItem, index) => (
              <MenuItem
                key={`Key-item-${index * 3}`}
                value={item.value}
                sx={{ fontSize: "11px" }}
              >
                {!item.activo ? <em>{item.etiqueta}</em> : item.etiqueta}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {message ? (
          <Typography
            component="div"
            sx={{
              fontSize: "11px",
              display: "flex",
              alignItems: "center",
              paddingTight: theme.spacing(1),
            }}
          >
            {message}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
}

FilterBarr.defaultProps = {
  message: undefined,
};
export { FilterBarr };
export default FilterBarr;
