import React from "react";
import DOMPurify from "dompurify";

interface DivSanitizedProps {
  text: string;
  className?: string;
  component?: "div" | "ul" | "span";
  onClick?: (event: any) => void;
}

const sanitizer = (html: string) =>
  DOMPurify.sanitize(html, { FORBID_TAGS: ["style"], FORBID_ATTR: ["style"] });

export function DivSanitized(props: DivSanitizedProps) {
  const { text, className, component } = props;
  return (
    <>
      {component === "span" ? (
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: sanitizer(text) }}
        />
      ) : null}
      {component === "div" ? (
        <div
          className={!className ? "noclass" : className}
          // onClick={onClick}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: sanitizer(text) }}
          role="presentation"
        />
      ) : null}
      {component === "ul" ? (
        <ul
          className={className ?? "noclass"}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: sanitizer(text) }}
        />
      ) : null}
    </>
  );
}

DivSanitized.defaultProps = {
  className: null,
  component: "div",
  onClick: null,
};

export default DivSanitized;
