import * as React from "react";
import { LinearProgress, Typography } from "@mui/material";

function Loading() {
  return (
    <Typography align="center" variant="caption">
      <LinearProgress color="success" sx={{ marginTop: "24px" }} />
      procesando...
    </Typography>
  );
}

export { Loading };
export default Loading;
