type typemessage = "error" | "info" | "success" | "warning";

interface alerta {
  accion: typemessage;
  message: string;
}
interface result {
  status: boolean;
  alert: alerta;
}

function useValidaForm() {
  function validaContenido(Item: any, accion: string) {
    const status: any = { status: true, name: "", mensaje: "" };
    const notificacion: alerta = { accion: "warning", message: "" };

    Object.keys(Item).forEach((key) => {
      if (key === "email" && Item[key]) {
        const pArroba = Item[key].lastIndexOf("@");
        const pPunto = Item[key].lastIndexOf(".");

        if (
          !(
            pArroba < pPunto &&
            pArroba > 0 &&
            Item[key].indexOf("@@") === -1 &&
            pPunto > 2 &&
            Item[key].length - pPunto > 2
          )
        ) {
          status.status = false;
          status.name = key;
          status.mensaje = "Por favor, ingresa un correo válido.";
        }
      }

      if (
        !Item[key] &&
        status.status &&
        key === "password" &&
        accion === "edit"
      ) {
        status.status = false;
        status.name = key;
      } else if (!Item[key] && status.status) {
        if (key !== "telefono2") {
          status.status = false;
          status.name = key;
        }
      }
    });

    if (!status.status) {
      notificacion.accion = "warning";
      notificacion.message = status.mensaje
        ? status.mensaje
        : `Informacón de ${status.name} incompleta.`;
    }

    const infomacion: result = { status: status.status, alert: notificacion };
    return infomacion;
  }

  function randomCoding() {
    let ranNum;
    ranNum = Math.floor(Math.random() * 25);
    ranNum += 97;
    return String.fromCharCode(ranNum);
  }

  return {
    validaContenido,
    randomCoding,
  };
}

export { useValidaForm };
export default useValidaForm;
