import React from "react";
import {
  Typography,
  Grid,
  Button,
  IconButton,
  Box,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  Facebook as FacebookIcon,
  YouTube as YouTubeIcon,
} from "@mui/icons-material/";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function Footer() {
  const theme = useTheme();
  return (
    <Grid container mt={2}>
      <Grid item xs={12} textAlign="center">
        <Typography variant="overline" fontWeight={"bold"}>
          Pedidos a los numeros
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <WhatsAppIcon />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="caption" pl={1}>
              6693335323
            </Typography>
            <Typography variant="caption" pl={1}>
              6691168968
            </Typography>
          </Box>
        </Box>
        <Box>
          <Tooltip title="Vista facebook ">
            <IconButton
              sx={{ color: theme.palette.common.white }}
              href="https://www.facebook.com/people/Carniceria-Mi-Esperanza/100040674615212/"
            >
              <FacebookIcon fontSize="medium" sx={{ color: "#1877f2" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Vista youtube ">
            <IconButton
              sx={{ color: theme.palette.common.white, mr: 1 }}
              href="https://m.youtube.com/@carnesmiesperanza/videos"
            >
              <YouTubeIcon fontSize="medium" sx={{ color: "#f00" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={12} textAlign="center">
        Derechos reservados a <Button href="/">CARNES MI ESPERANZA</Button>.
      </Grid>
    </Grid>
  );
}
export { Footer };
export default Footer;
