import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Container,
  Toolbar,
  Badge,
  IconButton,
  Box,
  ButtonGroup,
  Button,
  Typography,
  Autocomplete,
  TextField,
  Link,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Tooltip,
} from "@mui/material";

import { useTheme, alpha } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import {
  Menu as MenuIcon,
  ShoppingCartOutlined as CartIcon,
  Person2Outlined as PersonIcon,
  Search as SearchIcon,
  FmdGoodOutlined as UbicacionIcon,
  House as HouseIcon,
  TurnedIn as TurnedInIcon,
  Receipt as ReceiptIcon,
  Facebook as FacebookIcon,
  YouTube as YouTubeIcon,
} from "@mui/icons-material/";

import Logo from "./Logo";

import { usePeticiones } from "../useComplements";

export interface ScrolltopProps {
  window?: () => Window | undefined;
}

interface producto {
  Id: string;
  producto: string;
  descripcion: string;
  beneficios: string;
  UPCCode: string;
  BarrCode: string;
  categoria_id: string;
  iva: number;
  precio: number;
  imagen: string;
  activo: boolean;
}
interface categorias {
  categoria_id: number;
  categoria: string;
}

interface MOption {
  text: string;
  link: string;
  icon: any;
  option: Option[];
}
interface Option {
  text: string;
  link: string;
  icon: any;
}

interface productofirstLetter extends producto {
  firstLetter: string;
}

function HeaderPage() {
  const theme = useTheme();
  const { peticionAsync } = usePeticiones();
  const navigate = useNavigate();

  const [mount, setMount] = React.useState<boolean>(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openMobil, setOpenMobil] = React.useState<boolean>(false);

  const [productos, setProductos] = React.useState<productofirstLetter[]>([]);
  const [categoria, setCategoria] = React.useState<categorias[]>([]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenMobil(open);
    };

  const MenuOption: MOption[] = [
    { text: "inicio", link: "/", icon: <HouseIcon />, option: [] },
    {
      text: "Productos",
      link: "/productos/",
      icon: <TurnedInIcon />,
      option: [],
    },
    { text: "Recetas", link: "/recetas/", icon: <ReceiptIcon />, option: [] },
  ];

  const MenuPrincipal = (
    <ButtonGroup
      size="small"
      aria-label="small button group"
      variant="text"
      sx={{
        height: "fit-content",
        "& .MuiButtonBase-root ": {
          letterSpacing: "1px",
          color: theme.palette.primary.contrastText,
          fontWeight: "600",
          margin: "5px 0 6px 0",
          padding: "8px 9px",
          textTransform: "unset",
        },
        "& .MuiButtonGroup-grouped:not(:last-of-type)": {
          borderRight: `1px solid ${theme.palette.common.black} `,
          borderColor: theme.palette.common.black,
        },
      }}
    >
      <Button href="/" size="small">
        Inicio
      </Button>
      <Button
        size="small"
        id="menu-productos"
        aria-controls={open ? "menu-productos" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Productos
      </Button>
      <Button href="/recetas/" size="small">
        Recetas
      </Button>
      <Button href="/bolsa-de-trabajo/">Bolsa de trabajo</Button>
    </ButtonGroup>
  );

  const MenuMobilCat = (
    <Collapse in={true} timeout="auto" unmountOnExit>
      <List>
        {categoria.map((opt, index) => (
          <ListItem key={`Categoria-${index}-menuMobil`} disablePadding>
            <ListItemButton
              href={`/producto-categoria/${opt.categoria.toLowerCase()}`}
              onClick={() => setOpenMobil(false)}
              sx={{ pl: 8 }}
            >
              <ListItemText
                primary={`${opt.categoria[0].toUpperCase()}${opt.categoria
                  .substring(1)
                  .toLowerCase()}`}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Collapse>
  );

  const MenuMobil = (
    <List component={"nav"}>
      {MenuOption.map((opt, _index) => (
        <React.Fragment key={`list-option-${_index}-p`}>
          <ListItem disablePadding>
            <ListItemButton href={opt.link} onClick={() => setOpenMobil(false)}>
              <ListItemIcon>{opt.icon}</ListItemIcon>
              <ListItemText primary={opt.text} />
            </ListItemButton>
          </ListItem>
          {opt.text === "Productos" ? MenuMobilCat : null}
        </React.Fragment>
      ))}
    </List>
  );

  const handleChange = (event: any) => {
    if (event.target.value && event.code === "Enter") {
      const urlLink = event.target.value.replaceAll(" ", "-").toLowerCase();
      navigate(`/buscar-producto/${urlLink}`);
      navigate(0);
    }
  };

  const MenuProducto = (
    <Menu
      id="menu-productos"
      aria-labelledby="menu-productos"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{}}
    >
      {categoria.map((item, index) => (
        <MenuItem
          key={`Categoria-${index}-menu`}
          onClick={handleClose}
          href={`/producto-categoria/${item.categoria.toLowerCase()}`}
          component="a"
        >
          {`${item.categoria[0].toUpperCase()}${item.categoria
            .substring(1)
            .toLowerCase()}`}
        </MenuItem>
      ))}
    </Menu>
  );

  React.useEffect(() => {
    const body = {};

    const fetchdata = async (info: any) => {
      const result: any = await peticionAsync({
        accion: "admin/json-cat/productos/list",
        formData: info,
      });

      if (result.success) {
        const prods: producto[] = result.data.data;
        setCategoria(result.data.listCategorias);

        const options = prods.map((item: producto) => {
          const firstLetter: string = item.producto[0].toUpperCase();
          return {
            firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
            ...item,
          };
        });
        setProductos(options);
      }
    };
    if (!mount) {
      setMount(true);
      fetchdata(body);
    }
  }, [mount, peticionAsync]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setMount(false);
    }, 10);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          transition: "all 1s",
          backgroundImage: "url(/imagenes/Barra_Top_1.png)",
          backgroundRepeat: "repeat-x",
          backgroundPosition: "bottom",
          backgroundColor: "unset",
          opacity: "1",
          boxShadow: "unset",
          animation: {
            animation: "slide-down 1s",
            "@keyframes slide-down": {
              "0%": {
                opacity: "0",
              },
              "100%": {
                opacity: "1",
              },
            },
          },
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            sx={{
              margin: theme.spacing(1, 1),
            }}
          >
            <Box
              className="MenuMobil"
              sx={{
                flexGrow: 1,
                flexDirection: "column",
                display: { xs: "flex", md: "none" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                }}
              >
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setOpenMobil(true)}
                >
                  <MenuIcon />
                </IconButton>

                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "flex" },
                    justifyContent: "center",
                  }}
                >
                  <Link href="/">
                    <Logo customSize="5rem" />
                  </Link>
                </Box>

                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2, display: "none" }}
                >
                  <Badge badgeContent={4} color="info">
                    <CartIcon />
                  </Badge>
                </IconButton>
                <Box>
                  <Tooltip title="Vista facebook ">
                    <IconButton
                      sx={{ color: theme.palette.common.white }}
                      href="https://www.facebook.com/people/Carniceria-Mi-Esperanza/100040674615212/"
                    >
                      <FacebookIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Vista youtube ">
                    <IconButton
                      sx={{ color: theme.palette.common.white, mr: 1 }}
                      href="https://m.youtube.com/@carnesmiesperanza/videos"
                    >
                      <YouTubeIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>

            <Box
              className="MenuPC"
              sx={{
                display: { xs: "none", md: "flex" },
                flexGrow: 1,
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                }}
              >
                <Button>
                  <Link href="/">
                    <Logo customSize="5rem" />
                  </Link>
                </Button>

                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "flex" },
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {MenuPrincipal}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "end",
                  }}
                >
                  <Box>
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 2, display: "none" }}
                    >
                      <PersonIcon />
                    </IconButton>
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 2, display: "none" }}
                    >
                      <Badge badgeContent={4} color="info">
                        <CartIcon />
                      </Badge>
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      margin: theme.spacing(2, 0),
                      width: "185px",
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        marginRight: theme.spacing(1),
                        alignItems: "center",
                        fontSize: ".5rem",
                      }}
                    >
                      <UbicacionIcon
                        fontSize="small"
                        sx={{ color: red[500] }}
                      />
                      Servicio disponible solo en Mazatlón, Sinaloa.
                    </Typography>

                    <Tooltip title="Vista facebook ">
                      <IconButton
                        sx={{ color: theme.palette.common.white }}
                        href="https://www.facebook.com/people/Carniceria-Mi-Esperanza/100040674615212/"
                      >
                        <FacebookIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Vista youtube ">
                      <IconButton
                        sx={{ color: theme.palette.common.white, mr: 1 }}
                        href="https://m.youtube.com/@carnesmiesperanza/videos"
                      >
                        <YouTubeIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Toolbar>
          <Box
            sx={{
              paddingLeft: theme.spacing(1),
              paddingRight: theme.spacing(1),
            }}
          >
            <Box
              sx={{
                backgroundColor: theme.palette.common.black,
                paddingBottom: theme.spacing(1),
                borderRadius: "8px",
                boxShadow:
                  "rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px",
                "& svg": { color: theme.palette.common.white },
              }}
            >
              <Autocomplete
                id="grouped"
                options={productos.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                groupBy={(productos) => productos.firstLetter}
                getOptionLabel={(productos) => productos.producto}
                renderInput={(params) => (
                  <>
                    <SearchIcon sx={{ padding: theme.spacing(0, 2) }} />
                    <TextField
                      placeholder="Buscar Productos..."
                      variant="standard"
                      onKeyDown={handleChange}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      sx={{
                        "& .MuiInputBase-input": {
                          padding: theme.spacing(1, 1, 1, 0),
                          paddingLeft: `calc(1em + ${theme.spacing(4)})`,
                          transition: theme.transitions.create("width"),
                          width: "100%",
                          color: theme.palette.common.white,
                          [theme.breakpoints.up("md")]: {
                            width: "20ch",
                          },
                        },
                      }}
                    />
                  </>
                )}
                sx={{
                  display: "Flex",
                  alignItems: "center",
                  borderRadius: theme.spacing(1),
                  backgroundColor: alpha(theme.palette.common.white, 0.15),
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.common.white, 0.25),
                  },
                  marginRight: theme.spacing(1),
                  marginLeft: `${theme.spacing(1)} !important`,
                  [theme.breakpoints.up("sm")]: {
                    marginLeft: theme.spacing(3),
                    width: "auto",
                  },
                }}
              />
            </Box>
          </Box>
        </Container>
        <Drawer anchor={"left"} open={openMobil} onClose={toggleDrawer(false)}>
          {MenuMobil}
        </Drawer>
      </AppBar>
      {MenuProducto}
    </>
  );
}
export { HeaderPage };
export default HeaderPage;
