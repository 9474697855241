import * as React from "react";
import {
  FormControl,
  Input,
  InputLabel,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  DialogActions,
  DialogContent,
} from "@mui/material";

import {
  AddCircle as AddCircleIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material/";

import { CKEditor } from "ckeditor4-react";

import { useAppProvider } from "../../../providers/Appcontext";
import { ModLists } from "../";

import { usePeticiones, useValidaForm } from "../../../useComplements";

interface dataState {
  receta_id: string;
  titulo: string;
}

interface inputsState {
  titulo: string;
  contenido: string;
  status_id: string;
  activo: boolean;
}

interface modifyState {
  list: boolean;
  edit: boolean;
  id: number | null | string;
}

interface listStatus {
  id: string;
  code: string;
  status: string;
}

interface uploadResponse {
  fileLoader: any;
  message: String;
  fileName: String;
  url: String;
}

interface ModRecetasProps {}

function ModRecetas(props: ModRecetasProps) {
  const { setNotificacionMsg, setNotificacionAcc } = useAppProvider();
  const { peticionAsync } = usePeticiones();
  const { validaContenido } = useValidaForm();

  const iniDatos = () => {
    const datos: inputsState = {
      titulo: "",
      contenido: "",
      status_id: "",
      activo: false,
    };
    return datos;
  };

  const [listStatus, setListStatus] = React.useState<listStatus[]>([]);
  const [referencia, setReferencia] = React.useState<any>(null);
  const [mount, setMount] = React.useState<boolean>(true);
  const [data, setData] = React.useState<dataState[]>([]);
  const [inputs, setInputs] = React.useState<inputsState>(iniDatos());
  const routes: string = "admin/json-cat/recetas";

  const columnsHead = [
    {
      field: "code",
      headerName: "Code",
      width: 100,
    },
    {
      field: "titulo",
      headerName: "Titulo",
      minWidth: 150,
      flex: 1,
    },
  ];

  const [modify, setModify] = React.useState<modifyState>({
    list: true,
    edit: false,
    id: null,
  });

  // -------------------------------------
  // Control de Acciones Grabado DataBase
  // -------------------------------------

  const handleClickAdd = () => {
    const body = {
      titulo: inputs.titulo.toLocaleUpperCase("en-US"),
      contenido: inputs.contenido,
      status_id: inputs.status_id,
    };

    const fetchAdd = async (info: any) => {
      const result: any = await peticionAsync({
        accion: `${routes}/add`,
        formData: info,
      });

      if (result.success) {
        setMount(false);
        setNotificacionAcc("success");
        setNotificacionMsg("Registro agreagado exitosa.");
        setInputs(iniDatos());
        setModify({ list: true, edit: false, id: null });
      } else {
        setNotificacionAcc("warning");
        setNotificacionMsg("Registro agreagado fallido.");
        setInputs({ ...inputs, activo: false });
      }
    };

    const valida = validaContenido(body, modify.edit ? "edit" : "");
    if (valida.status) {
      setInputs({ ...inputs, activo: true });
      fetchAdd(body);
    } else {
      setNotificacionAcc(valida.alert.accion);
      setNotificacionMsg(valida.alert.message);
      setInputs({ ...inputs, activo: false });
    }
  };

  const handleClickEdit = (id: any) => {
    setModify({ list: false, edit: true, id: id });

    const reesult: any = data.filter((a: any) => a.id === id)[0];

    const body = {
      titulo: reesult.titulo.toLocaleUpperCase("en-US"),
      contenido: reesult.contenido,
      status_id: reesult.status_id,
      activo: false,
    };
    setInputs(body);
  };

  const handleClickDelete = (id: any) => {
    const body = { id: id };
    setInputs({ ...inputs, activo: true });

    const fetchDelete = async (info: any) => {
      const result: any = await peticionAsync({
        accion: `${routes}/delete`,
        formData: info,
      });

      if (result.success) {
        setMount(false);
        setNotificacionAcc("success");
        setNotificacionMsg("Eliminación correctamente.");
        setInputs(iniDatos());
        setModify({ list: true, edit: false, id: null });
      } else {
        setNotificacionAcc("error");
        setNotificacionMsg("Eliminación no realizada.");
        setInputs({ ...inputs, activo: false });
      }
    };
    fetchDelete(body);
  };

  const handleClickGrabar = (key: number | null | string) => {
    const body = {
      id: key,
      titulo: inputs.titulo.toLocaleUpperCase("en-US"),
      contenido: inputs.contenido,
      status_id: inputs.status_id,
      ref_id: referencia,
    };

    setInputs({ ...inputs, activo: true });

    const fetchUpdate = async (info: any) => {
      const result: any = await peticionAsync({
        accion: `${routes}/update`,
        formData: info,
      });

      if (result.success) {
        setMount(false);
        setNotificacionAcc("success");
        setNotificacionMsg("Actualización exitosa.");
        setInputs(iniDatos());
        setModify({ list: true, edit: false, id: null });
      } else {
        setNotificacionAcc("success");
        setNotificacionMsg("Actualización fallida.");
        setInputs({ ...inputs, activo: false });
      }
    };

    const valida = validaContenido(body, modify.edit ? "edit" : "");

    if (valida.status) {
      setInputs({ ...inputs, activo: true });
      fetchUpdate(body);
    } else {
      setNotificacionAcc(valida.alert.accion);
      setNotificacionMsg(valida.alert.message);
      setInputs({ ...inputs, activo: false });
    }
  };

  // -------------------------------------
  // Fin de Acciones Grabado DataBase
  // -------------------------------------

  const handleChange =
    (prop: keyof inputsState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputs({ ...inputs, [prop]: event.target.value });
    };

  const handleChangeEditor = (event: any) => {
    if (event.editor.getData()) {
      const data: string = event.editor.getData();
      setInputs({ ...inputs, contenido: data });
    }
  };

  const handleChangeEditorUpload = (event: any) => {
    if (event?.data) {
      const valor: uploadResponse = event?.data;
      console.log(valor);
    }
  };

  const handleChangecategoria = (event: SelectChangeEvent) => {
    setInputs({ ...inputs, status_id: event.target.value });
  };

  const handleChangeActiveAdd = () => {
    setModify({ list: false, edit: false, id: null });
  };

  const handleClickEditCancel = () => {
    setInputs(iniDatos());
    setModify({ list: true, edit: false, id: null });
  };

  React.useEffect(() => {
    const body = {};

    const fetchdata = async (info: any) => {
      const result: any = await peticionAsync({
        accion: `${routes}/list`,
        formData: info,
      });
      if (result.success) {
        setData(result.data.data);
        setListStatus(result.data.listRecetasStatus);
      }
    };

    if (!mount) {
      if (!referencia) {
        setReferencia(
          new Date()
            .toLocaleString()
            .replaceAll("/", "")
            .replaceAll(", ", "")
            .replaceAll(":", "")
        );
      }
      setMount(true);
      fetchdata(body);
    }
  }, [mount, peticionAsync, referencia]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setMount(false);
    }, 10);
    return () => clearTimeout(timer);
  }, []);

  console.log("r:", referencia);

  return (
    <>
      {modify.list ? (
        <>
          {data.length > 0 ? (
            <>
              <ModLists
                rowsList={data}
                columnsHead={columnsHead}
                selectRow={handleClickEdit}
                deleteRow={handleClickDelete}
              />
              <DialogActions>
                <Button
                  onClick={handleChangeActiveAdd}
                  variant="contained"
                  size="small"
                  endIcon={<AddCircleIcon />}
                >
                  Agregar
                </Button>
              </DialogActions>
            </>
          ) : null}
        </>
      ) : (
        <>
          <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: { sm: "flex" },
                flexDirection: "column",
                height: "400px",
                maxHeight: "600px",
                width: "100%",
                "& input": { textTransform: "uppercase" },
              }}
            >
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="input-producto">Producto</InputLabel>
                <Input
                  autoComplete="off"
                  id="input-producto"
                  type="text"
                  value={inputs.titulo}
                  onChange={handleChange("titulo")}
                  fullWidth
                  disabled={inputs.activo}
                />
              </FormControl>
              <FormControl
                variant="standard"
                sx={{
                  mb: 1,
                  mr: 1,
                  width: "100%",
                  marginLeft: "8px",
                }}
              >
                <InputLabel id="input-producto-status-label">Status</InputLabel>
                <Select
                  labelId="input-producto-status-label"
                  id="input-producto-status"
                  value={inputs.status_id}
                  onChange={handleChangecategoria}
                  label="status"
                >
                  <MenuItem value="">None</MenuItem>
                  {listStatus
                    ? listStatus.map((item: listStatus, index: number) => (
                        <MenuItem
                          selected
                          key={`list${index.toString()}-listStatus`}
                          value={item.id}
                          sx={{ textTransform: "uppercase" }}
                        >
                          ({item.code}) {item.status}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  m: 1,
                  width: "100%",
                }}
                variant="standard"
              >
                <CKEditor
                  name="my-ckeditor"
                  initData={inputs.contenido}
                  editorUrl="https://ckeditor.com/docs/vendors/4.21.0/ckeditor/ckeditor.js"
                  onChange={handleChangeEditor}
                  onFileUploadResponse={handleChangeEditorUpload}
                  onDialogShow={(e: any) => {
                    const dialogName = e.data.name;
                    console.log(dialogName);
                  }}
                  config={{
                    height: 220,
                    toolbar: [
                      {
                        name: "document",
                        items: [
                          "Source",
                          "-",
                          "NewPage",
                          "Preview",
                          "-",
                          "Templates",
                        ],
                      },
                      {
                        name: "basicstyles",
                        items: [
                          "Bold",
                          "Italic",
                          "Underline",
                          "Strike",
                          "-",
                          "RemoveFormat",
                        ],
                      },
                      {
                        name: "paragraph",
                        groups: ["list", "indent", "blocks", "align", "bidi"],
                        items: [
                          "NumberedList",
                          "BulletedList",
                          "-",
                          "Outdent",
                          "Indent",
                          "-",
                          "Blockquote",
                          "CreateDiv",
                          "-",
                          "JustifyLeft",
                          "JustifyCenter",
                          "JustifyRight",
                          "JustifyBlock",
                          "-",
                          "BidiLtr",
                          "BidiRtl",
                          "Language",
                        ],
                      },

                      { name: "links", items: ["Link", "Unlink"] },
                      {
                        name: "insert",
                        items: [
                          "Image",
                          "Table",
                          "HorizontalRule",
                          "SpecialChar",
                        ],
                      },
                      {
                        name: "styles",
                        items: ["Styles", "Format", "Font", "FontSize"],
                      },
                      { name: "colors", items: ["TextColor", "BGColor"] },
                    ],
                    extraPlugins: "uploadimage",
                    uploadUrl: `http://api.data.local/admin/json-cat/imagenReceta?ref=${referencia}&id=${modify.id}`,
                  }}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            {modify.edit ? (
              <>
                <Button
                  onClick={() => handleClickGrabar(modify.id)}
                  variant="contained"
                  endIcon={<CheckCircleIcon />}
                  sx={{ mr: 1 }}
                >
                  Grabar
                </Button>
                <Button
                  onClick={handleClickEditCancel}
                  variant="outlined"
                  endIcon={<CancelIcon />}
                >
                  Cancelar
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={handleClickEditCancel}
                  variant="outlined"
                  endIcon={<CancelIcon />}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={handleClickAdd}
                  variant="contained"
                  endIcon={<AddCircleIcon />}
                >
                  Agregar
                </Button>
              </>
            )}
          </DialogActions>
        </>
      )}
    </>
  );
}

export { ModRecetas };
export default ModRecetas;
