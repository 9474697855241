import * as React from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  Typography,
  LinearProgress,
  AppBar,
  Toolbar,
  Button,
  Box,
} from "@mui/material";

import {
  FileOpen as FileOpenIcon,
  Source as SourceIcon,
  Clear as ClearIcon,
} from "@mui/icons-material/";

import { blue } from "@mui/material/colors";

import {
  ModUsers,
  ModUserTipo,
  ModProductos,
  ModProductosCategoria,
  ModRecetas,
  ModRecetasStatus,
} from "../components/admin/modules";

import { usePeticiones, useLocalstorage } from "../useComplements";
import { ModDialog } from "../components/admin/";

interface moduloState {
  descripcion: string;
  icon: string;
  accesos: accesoState[];
}

interface accesoState {
  descripcion: string;
  icon: string;
  route: string;
}

interface moduloActive {
  titulo: string;
  descripcion: string;
  outlet: JSX.Element;
}

function BlockCard({ moduloCard }: any) {
  const item: moduloState = moduloCard;
  const { descripcion, icon, accesos } = item;

  const [open, setOpen] = React.useState<boolean>(false);
  const [modulos, setModulos] = React.useState<moduloActive>({
    titulo: "",
    descripcion: "",
    outlet: <div />,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const selectModulos = (route: string) => {
    if (route === "/admin/registro_user") {
      return <ModUsers />;
    }
    if (route === "/admin/registro_user_tipo") {
      return <ModUserTipo />;
    }
    if (route === "/admin/registro_producto") {
      return <ModProductos />;
    }
    if (route === "/admin/registro_producto_categoria") {
      return <ModProductosCategoria />;
    }
    if (route === "/admin/recetas/registro") {
      return <ModRecetas />;
    }
    if (route === "/admin/recetas/registro_status") {
      return <ModRecetasStatus />;
    }
    return <div> no hay relación </div>;
  };

  const activeModal = (tile: string, route: string) => {
    setModulos({
      titulo: tile,
      descripcion: "",
      outlet: selectModulos(route),
    });
    setOpen(true);
  };

  return (
    <>
      <Card>
        <CardHeader
          sx={{
            bgcolor: blue[200],
            color: blue[900],
            fontWeight: "bold",
            fontSize: "1rem",
          }}
          avatar={
            <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe">
              {icon}
              <SourceIcon />
            </Avatar>
          }
          title={descripcion}
        />
        <CardContent sx={{ borderTop: "1px solid" }}>
          <Grid sx={{ flexGrow: 1 }} container spacing={2}>
            <Grid
              item
              xs={12}
              p={2}
              sx={{ flexDirection: "row", display: "contents" }}
            >
              {accesos.map((acceso: accesoState, index) => (
                <Grid item p={2} key={`block-${index.toString()}-aceesos`}>
                  <IconButton
                    color="primary"
                    aria-label="settings"
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                    onClick={() =>
                      activeModal(acceso.descripcion, acceso.route)
                    }
                  >
                    <FileOpenIcon fontSize="large" />
                    <Typography>{acceso.descripcion}</Typography>
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ModDialog
        open={open}
        maxWidth="sm"
        fullWidth
        handleClose={handleClose}
        modulo={modulos}
      />
    </>
  );
}

function PageDashboard() {
  const { peticionAsync } = usePeticiones();
  const { logOut } = useLocalstorage();
  const [mount, setMount] = React.useState<boolean>(true);
  const [modulos, setModulos] = React.useState<moduloState[]>();

  React.useEffect(() => {
    const body = {};

    const fetchdata = async (info: any) => {
      const result: any = await peticionAsync({
        accion: "admin/dashboard",
        formData: info,
      });
      if (result.success) {
        setModulos(result.data);
      }
    };

    if (!mount) {
      setMount(true);
      fetchdata(body);
    }
  }, [mount, peticionAsync]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setMount(false);
    }, 10);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <Button color="inherit" href="/admin" onClick={() => logOut()}>
            <ClearIcon /> Salir
          </Button>
        </Toolbar>
      </AppBar>
      {!mount || modulos === undefined ? (
        <LinearProgress />
      ) : (
        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              rowSpacing={2}
              p={2}
              justifyContent="left"
              spacing={2}
            >
              {!modulos ? (
                <Grid item xs={12} md={6}>
                  sin Contenido
                </Grid>
              ) : (
                modulos.map((item: moduloState, index) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={`block-${index.toString()}-modulos`}
                  >
                    <BlockCard moduloCard={item} />
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export { PageDashboard };
export default PageDashboard;
