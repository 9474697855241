import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useLocalstorage } from "../useComplements/useLocalstorage";
interface PrivateRouteProps {
  redirectPath?: string;
}

const VerifyRoute = ({ redirectPath = "/dashboard" }: PrivateRouteProps) => {
  const { isAutorizate } = useLocalstorage();
  if (isAutorizate()) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export { VerifyRoute };
export default VerifyRoute;
