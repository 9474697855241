import * as React from "react";
import {
  FormControl,
  Input,
  InputLabel,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  DialogActions,
  DialogContent,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";

import {
  AddCircle as AddCircleIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material/";

import { useAppProvider } from "../../../providers/Appcontext";
import { ModLists } from "../";

import { usePeticiones, useValidaForm } from "../../../useComplements";

interface dataState {
  user_id: number;
  nombre: string;
  usuario: string;
}

interface inputsState {
  usuario: string;
  nombre: string;
  email: string;
  telefono: string;
  password: string;
  showPassword: boolean;
  tipo_id: string;
  avatar: string;
  activo: boolean;
}

interface modifyState {
  list: boolean;
  edit: boolean;
  id: number | null | string;
}

interface listTipos {
  id: string;
  tipo: string;
}

interface ModUsersProps {}

function ModUsers(props: ModUsersProps) {
  const { setNotificacionMsg, setNotificacionAcc } = useAppProvider();
  const { peticionAsync } = usePeticiones();
  const { validaContenido } = useValidaForm();

  const iniDatos = () => {
    const datos: inputsState = {
      usuario: "",
      nombre: "",
      email: "",
      telefono: "",
      password: "",
      showPassword: false,
      tipo_id: "",
      avatar: "",
      activo: false,
    };
    return datos;
  };

  const [tipos, setTipos] = React.useState<listTipos[]>([]);
  const [mount, setMount] = React.useState<boolean>(true);
  const [data, setData] = React.useState<dataState[]>([]);
  const [inputs, setInputs] = React.useState<inputsState>(iniDatos());
  const routes: string = "admin/json-cat/users";
  const columnsHead = [
    {
      field: "usuario",
      headerName: "Usuario",
      width: 150,
    },
    {
      field: "nombre",
      headerName: "Nombre",
      minWidth: 150,
      flex: 1,
    },
  ];

  const [modify, setModify] = React.useState<modifyState>({
    list: true,
    edit: false,
    id: null,
  });

  // -------------------------------------
  // Control de Acciones Grabado DataBase
  // -------------------------------------

  const handleClickAdd = () => {
    const body = {
      usuario: inputs.usuario,
      nombre: inputs.nombre.toLocaleUpperCase("en-US"),
      email: inputs.email.toLocaleUpperCase("en-US"),
      telefono: inputs.telefono,
      password: inputs.password,
      tipo_id: inputs.tipo_id,
      avatar: "avatarimagen",
    };

    const fetchAdd = async (info: any) => {
      const result: any = await peticionAsync({
        accion: `${routes}/add`,
        formData: info,
      });

      if (result.success) {
        setMount(false);
        setNotificacionAcc("success");
        setNotificacionMsg("Registro agreagado exitosa.");
        setInputs(iniDatos());
        setModify({ list: true, edit: false, id: null });
      } else {
        setNotificacionAcc("warning");
        setNotificacionMsg("Registro agreagado fallido.");
        setInputs({ ...inputs, activo: false });
      }
    };

    const valida = validaContenido(body, modify.edit ? "edit" : "");
    if (valida.status) {
      setInputs({ ...inputs, activo: true });
      fetchAdd(body);
    } else {
      setNotificacionAcc(valida.alert.accion);
      setNotificacionMsg(valida.alert.message);
      setInputs({ ...inputs, activo: false });
    }
  };

  const handleClickEdit = (id: any) => {
    setModify({ list: false, edit: true, id: id });

    const reesult: any = data.filter((a: any) => a.id === id)[0];

    const body = {
      usuario: reesult.usuario,
      nombre: reesult.nombre.toLocaleUpperCase("en-US"),
      email: reesult.email.toLocaleUpperCase("en-US"),
      telefono: reesult.telefono,
      password: "",
      showPassword: false,
      tipo_id: reesult.tipo_id,
      avatar: "avatarimagen",
      activo: false,
    };

    setInputs(body);
  };

  const handleClickDelete = (id: any) => {
    const body = { id: id };
    setInputs({ ...inputs, activo: true });

    const fetchDelete = async (info: any) => {
      const result: any = await peticionAsync({
        accion: `${routes}/delete`,
        formData: info,
      });

      if (result.success) {
        setMount(false);
        setNotificacionAcc("success");
        setNotificacionMsg("Eliminación correctamente.");
        setInputs(iniDatos());
        setModify({ list: true, edit: false, id: null });
      } else {
        setNotificacionAcc("error");
        setNotificacionMsg("Eliminación no realizada.");
        setInputs({ ...inputs, activo: false });
      }
    };
    fetchDelete(body);
  };

  const handleClickGrabar = (key: number | null | string) => {
    const body = inputs.password
      ? {
          id: key,
          usuario: inputs.usuario,
          nombre: inputs.nombre.toLocaleUpperCase("en-US"),
          email: inputs.email.toLocaleUpperCase("en-US"),
          telefono: inputs.telefono,
          password: inputs.password,
          tipo_id: inputs.tipo_id,
          avatar: inputs.avatar,
        }
      : {
          id: key,
          usuario: inputs.usuario,
          nombre: inputs.nombre.toLocaleUpperCase("en-US"),
          email: inputs.email.toLocaleUpperCase("en-US"),
          telefono: inputs.telefono,
          tipo_id: inputs.tipo_id,
          avatar: inputs.avatar,
        };

    setInputs({ ...inputs, activo: true });

    const fetchUpdate = async (info: any) => {
      const result: any = await peticionAsync({
        accion: `${routes}/update`,
        formData: info,
      });

      if (result.success) {
        setMount(false);
        setNotificacionAcc("success");
        setNotificacionMsg("Actualización exitosa.");
        setInputs(iniDatos());
        setModify({ list: true, edit: false, id: null });
      } else {
        setNotificacionAcc("success");
        setNotificacionMsg("Actualización fallida.");
        setInputs({ ...inputs, activo: false });
      }
    };

    const valida = validaContenido(body, modify.edit ? "edit" : "");

    if (valida.status) {
      setInputs({ ...inputs, activo: true });
      fetchUpdate(body);
    } else {
      setNotificacionAcc(valida.alert.accion);
      setNotificacionMsg(valida.alert.message);
      setInputs({ ...inputs, activo: false });
    }
  };

  // -------------------------------------
  // Fin de Acciones Grabado DataBase
  // -------------------------------------
  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChange =
    (prop: keyof inputsState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputs({ ...inputs, [prop]: event.target.value });
    };

  const handleChangeUser = (event: SelectChangeEvent) => {
    setInputs({ ...inputs, tipo_id: event.target.value });
  };

  const handleChangeActiveAdd = () => {
    setModify({ list: false, edit: false, id: null });
  };

  const handleClickEditCancel = () => {
    setInputs(iniDatos());
    setModify({ list: true, edit: false, id: null });
  };

  React.useEffect(() => {
    const body = {};

    const fetchdata = async (info: any) => {
      const result: any = await peticionAsync({
        accion: `${routes}/list`,
        formData: info,
      });
      if (result.success) {
        setData(result.data.data);
        setTipos(result.data.listTipos);
      }
    };

    if (!mount) {
      setMount(true);
      fetchdata(body);
    }
  }, [mount, peticionAsync]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setMount(false);
    }, 10);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {modify.list ? (
        <>
          {data.length > 0 ? (
            <>
              <ModLists
                rowsList={data}
                columnsHead={columnsHead}
                selectRow={handleClickEdit}
                deleteRow={handleClickDelete}
              />
              <DialogActions>
                <Button
                  onClick={handleChangeActiveAdd}
                  variant="contained"
                  size="small"
                  endIcon={<AddCircleIcon />}
                >
                  Agregar
                </Button>
              </DialogActions>
            </>
          ) : null}
        </>
      ) : (
        <>
          <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: { sm: "flex" },
                flexDirection: "column",
                height: "400px",
                maxHeight: "600px",
                width: "100%",
                "& input": { textTransform: "uppercase" },
              }}
            >
              <FormControl
                variant="standard"
                sx={{
                  m: 1,
                  width: "100%",
                  "& input": { textTransform: "none" },
                }}
              >
                <InputLabel htmlFor="input-usuario">Usuario</InputLabel>
                <Input
                  autoComplete="off"
                  id="input-usuario"
                  type="text"
                  value={inputs.usuario}
                  onChange={handleChange("usuario")}
                  fullWidth
                  disabled={inputs.activo}
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="input-nombre">Nombre</InputLabel>
                <Input
                  autoComplete="off"
                  id="input-nombre"
                  type="text"
                  value={inputs.nombre}
                  onChange={handleChange("nombre")}
                  fullWidth
                  disabled={inputs.activo}
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="input-email">Email</InputLabel>
                <Input
                  autoComplete="off"
                  id="input-email"
                  type="text"
                  value={inputs.email}
                  onChange={handleChange("email")}
                  fullWidth
                  disabled={inputs.activo}
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="input-telefono">Telefono</InputLabel>
                <Input
                  autoComplete="off"
                  id="input-telefono"
                  type="text"
                  value={inputs.telefono}
                  onChange={handleChange("telefono")}
                  fullWidth
                  disabled={inputs.activo}
                />
              </FormControl>
              <FormControl
                sx={{
                  m: 1,
                  width: "100%",
                  "& input": { textTransform: "none" },
                }}
                variant="standard"
              >
                <InputLabel htmlFor="input-user-password">
                  {modify.edit ? "Cambiar password" : "password"}
                </InputLabel>
                <Input
                  autoComplete="off"
                  id="input-user-Password"
                  type={inputs.showPassword ? "text" : "password"}
                  value={inputs.password}
                  onChange={handleChange("password")}
                  fullWidth
                  disabled={inputs.activo}
                  endAdornment={
                    <InputAdornment position="end">
                      <Tooltip title="Buscar usuarios">
                        <IconButton
                          aria-label="password-visibility"
                          component="span"
                          edge="end"
                          onClick={() =>
                            setInputs({
                              ...inputs,
                              showPassword: !inputs.showPassword,
                            })
                          }
                          onMouseDown={handleMouseDown}
                          sx={{ mr: 1 }}
                        >
                          {inputs.showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <FormControl
                variant="standard"
                sx={{
                  mb: 1,
                  mr: 1,
                  width: "100%",
                  marginLeft: "8px",
                }}
              >
                <InputLabel id="input-producto-tipo-label">Tipo</InputLabel>
                <Select
                  labelId="input-producto-tipo-label"
                  id="input-producto-tipo"
                  value={inputs.tipo_id}
                  onChange={handleChangeUser}
                  label="Tipo"
                >
                  <MenuItem value="">None</MenuItem>
                  {tipos
                    ? tipos.map((itemUser: listTipos, index: number) => (
                        <MenuItem
                          selected
                          key={`list${index.toString()}-tipo`}
                          value={itemUser.id}
                          sx={{ textTransform: "uppercase" }}
                        >
                          {itemUser.tipo}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            {modify.edit ? (
              <>
                <Button
                  onClick={() => handleClickGrabar(modify.id)}
                  variant="contained"
                  endIcon={<CheckCircleIcon />}
                  sx={{ mr: 1 }}
                >
                  Grabar
                </Button>
                <Button
                  onClick={handleClickEditCancel}
                  variant="outlined"
                  endIcon={<CancelIcon />}
                >
                  Cancelar
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={handleClickEditCancel}
                  variant="outlined"
                  endIcon={<CancelIcon />}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={handleClickAdd}
                  variant="contained"
                  endIcon={<AddCircleIcon />}
                >
                  Agregar
                </Button>
              </>
            )}
          </DialogActions>
        </>
      )}
    </>
  );
}

export { ModUsers };
export default ModUsers;
