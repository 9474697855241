import jwt_decode, { JwtPayload } from "jwt-decode";

interface Datoslogin {
  Avatar: string;
  Token: string;
}

interface optMenu {
  text?: string;
  link?: string;
  icon?: string;
  unit?: number;
  active?: boolean;
}

type Theme = true | false;
type location = "top" | "left" | "bottom" | "right";

interface DatosSetting {
  Theme?: Theme;
  Menulocation?: string;
  PosMenu?: number;
}

interface Scopes {
  activated: string;
  id: number | null;
  user: string;
  nombre: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  vigencia: string;
  email: string;
  cuenta: string;
  estudiante: string;
  permisos: string | number;
  home: string;
}

function useLocalstorage() {
  // const { setToken, onLogOut } = React.useContext<any>(Appcontext);

  function updateUnits(units: optMenu[]) {
    let copy;

    const Conten = localStorage.getItem("setConten");
    const unitStoraged =
      Conten === "undefined" ? null : JSON.parse(Conten as string);
    if (Array.isArray(unitStoraged)) {
      copy = Object.assign(unitStoraged, units);
    } else {
      copy = units;
    }
    localStorage.setItem("setConten", JSON.stringify(copy));
  }

  function getUnits() {
    const Conten = localStorage.getItem("setConten");
    let result: optMenu[] = [];
    if (Conten !== null && Conten !== "") {
      result = JSON.parse(Conten);
    }
    return result;
  }
  function getClasses() {
    const Conten = localStorage.getItem("virtul-classes");
    let result: any = null;
    if (Conten !== null && Conten !== "") {
      result = JSON.parse(Conten);
    }
    return result;
  }
  async function setClasses(data: any) {
    try {
      const result = await localStorage.setItem(
        "virtul-classes",
        JSON.stringify(data)
      );
      return result;
    } catch (error) {
      return error;
    }
  }

  function updateLoginLS(datos: Datoslogin) {
    const Config = localStorage.getItem("setConfig");
    let copy;

    if (Config !== null && Config !== "") {
      copy = Object.assign(JSON.parse(Config), datos);
    } else {
      copy = datos;
    }
    localStorage.setItem("setConfig", JSON.stringify(copy));
  }

  function setSetting(datos: DatosSetting) {
    const Setting = localStorage.getItem("setSetting");
    let copy;

    if (Setting !== null && Setting !== "") {
      copy = Object.assign(JSON.parse(Setting), datos);
    } else {
      copy = datos;
    }
    localStorage.setItem("setSetting", JSON.stringify(copy));
  }

  function logOut() {
    //setToken(null);
    //onLogOut();
    localStorage.removeItem("setConfig");
  }

  function getTheme(): boolean {
    const Setting = localStorage.getItem("setSetting") ?? null;

    if (Setting !== null && Setting !== "") {
      const stateTheme: DatosSetting = JSON.parse(Setting);
      if (!stateTheme.Theme) {
        setSetting({ Theme: false });
      }
      return stateTheme.Theme ?? false;
    }
    setSetting({ Theme: false });
    return false;
  }

  function getMenulocation(): location {
    const Setting = localStorage.getItem("setSetting");
    if (Setting !== null && Setting !== "") {
      const stateMenulocation: DatosSetting = JSON.parse(Setting);

      if (!stateMenulocation!.Menulocation) {
        setSetting({ Menulocation: "left" });
      }
      return (stateMenulocation.Menulocation as location) ?? "left";
    }
    setSetting({ Menulocation: "left" });
    return "left";
  }

  function getPosMenu(): number {
    const Setting = localStorage.getItem("setSetting") ?? null;

    if (Setting !== null && Setting !== "") {
      const statePosMenu: DatosSetting = JSON.parse(Setting);
      return statePosMenu.PosMenu ?? 0;
    }
    setSetting({ PosMenu: 0 });
    return 0;
  }

  function getToken(): string {
    const Config = localStorage.getItem("setConfig");
    if (Config !== null && Config !== "") {
      const stateConfig: Datoslogin = JSON.parse(Config);
      // setToken(stateConfig.Token);
      return stateConfig.Token ?? "";
    }
    return "";
  }

  function getAvatar(): string | null {
    const Config = localStorage.getItem("setConfig");
    if (Config !== null && Config !== "") {
      const stateConfig: Datoslogin = JSON.parse(Config);
      return stateConfig.Avatar ?? null;
    }
    return null;
  }

  function updateAvatar(Avatar: string): string {
    const Config = localStorage.getItem("setConfig");
    if (Config !== null && Config !== "") {
      const stateConfig: Datoslogin = JSON.parse(Config);
      stateConfig.Avatar = Avatar;
      localStorage.setItem("setConfig", JSON.stringify(stateConfig));
    }
    return "";
  }

  function isAutorizate(): boolean {
    let valor = false;

    const token = getToken();
    if (token) {
      const TokenDecode = jwt_decode<JwtPayload>(token).exp;
      const currentTime = Date.now() / 1000;
      if (TokenDecode) {
        if (TokenDecode > currentTime) {
          valor = true;
        }
      }
    }
    return valor;
  }

  function getTokenScopes(): Scopes {
    let valor = {
      activated: "0",
      id: null,
      user: "",
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      vigencia: "",
      email: "",
      cuenta: "",
      estudiante: "",
      permisos: "0",
      home: "",
    };

    const token = getToken();
    if (token) {
      const TokenDecode: any = jwt_decode(token);
      valor = TokenDecode.scopes;
    }
    return valor;
  }

  function isAutorizateTmp(token: string): boolean {
    let valor = false;

    // eslint-disable-next-line
    console.log("p:", token);

    if (token) {
      const TokenDecode = jwt_decode<JwtPayload>(token).exp;
      const currentTime = Date.now() / 1000;
      if (TokenDecode) {
        if (TokenDecode > currentTime) {
          valor = true;
        }
      }
    }

    return valor;
  }

  function datosAutorizateTmp(token: string): boolean {
    let datos: any;

    if (token) {
      const TokenDecode: any = jwt_decode(token);
      datos = TokenDecode.scopes;
    }
    return datos;
  }

  return {
    updateLoginLS,
    isAutorizate,
    isAutorizateTmp,
    datosAutorizateTmp,
    getTheme,
    getMenulocation,
    setSetting,
    logOut,
    getToken,
    getTokenScopes,
    getAvatar,
    updateAvatar,
    updateUnits,
    getUnits,
    getPosMenu,
    getClasses,
    setClasses,
  };
}

export { useLocalstorage };
export default useLocalstorage;
