import React from "react";
import { Container, Divider, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { FloatingWhatsApp } from "react-floating-whatsapp";
import { ScrollTop } from "../components/";

import MapaUbicacion from "../components/MapaUbicacion";
import ListCategorias from "../components/ListCategorias";
import Somos from "../components/Somos";
import AnuncioProductos from "../components/AnuncioProductos";
import AnuncioTienda from "../components/AnuncioTienda";
import { MenuSlider } from "../components/MenuSlider";
import Footer from "../components/Footer";

function PageHome() {
  const theme = useTheme();

  return (
    <>
      <ScrollTop />
      <MenuSlider />
      <AnuncioProductos />
      <AnuncioTienda />
      <Divider />
      <Somos />
      <Divider />
      <ListCategorias />
      <Divider />
      <Grid container>
        <Grid item sm={12} sx={{ backgroundColor: theme.palette.grey[50] }}>
          <Container maxWidth="xl">
            <MapaUbicacion />
          </Container>
        </Grid>
      </Grid>

      <Footer />
      <FloatingWhatsApp
        phoneNumber="+526693335323"
        accountName="Carnes Mi Esperanza"
        chatMessage="¡Hola! 🤝 ¿Cómo podemos ayudar?"
        statusMessage="Normalmente responde en 20 minutos."
        avatar={`${process.env.PUBLIC_URL}/icons/favicon-96x96.png`}
        allowEsc
        allowClickAway
        notification
        notificationSound
      />
    </>
  );
}
export { PageHome };
export default PageHome;
