import React from "react";
import { Container, Box, Grid, Typography, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function AnuncioProductos() {
  const theme = useTheme();

  const imgAll: string = `url(${process.env.PUBLIC_URL}/imagenes/Carnes-All.webp),url(${process.env.PUBLIC_URL}/imagenes/Carnes-All.png)`;

  return (
    <Box
      component="section"
      sx={{
        padding: theme.spacing(2, 0, 3, 0),
        backgroundSize: "cover",
        backgroundImage: {
          xs: "unset",
          md: `url(/imagenes/madera.webp),url(/imagenes/madera.jpg)`,
        },
      }}
    >
      <Container>
        <Grid sx={{ display: { xs: "none", md: "block" } }}>
          <Grid
            item
            sx={{
              display: { xs: "none", md: "block" },
              justifyContent: "center",
              padding: theme.spacing(2, 0, 2, 0),
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "900",
                paddingBottom: theme.spacing(4),
              }}
            >
              Bienvenido a Mi Esperanza
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            position: "relative",
            justifyContent: "center",
            alignItems: "flex-start",
            flexWrap: "wrap",
            paddingTop: { xs: "0", md: "255px" },
            boxSizing: "border-box",
            backgroundImage: {
              xs: "unset",
              md: `${imgAll}`,
            },
            backgroundRepeat: "no-repeat",
            backgroundSize: "95%",
            backgroundPosition: "bottom center",
            // </Container>backgroundSize: 'cover',
            "& .size-w-68": {
              width: "68.2%",
              maxWidth: "100%",
              display: { xs: "none", md: "inline-block" },
            },
          }}
        >
          <img
            className="size-w-68"
            src={`${process.env.PUBLIC_URL}/imagenes/transp.png`}
            alt="Carnes-All"
          />

          <Box
            sx={{
              transition: "transform 1s, height 2s",
              padding: "10px 0",
              margin: "10px 0",
              width: { xs: "100%", md: "20%" },
              position: { xs: "unset", md: "absolute" },
              top: "24%",
              left: "0",
              "&:hover": {
                border: "1px solid #755956",
                backgroundColor: "#f7e4e2a8",
                borderRadius: "8px",
                borderStyle: "dashed",
                cursor: "pointer",
                transform: "scale(1.1)",
                "& .ImagenIcon": {
                  backgroundImage: `url(${process.env.PUBLIC_URL}/imagenes/icons/icon1.png)`,
                },
              },
            }}
          >
            <Link href="/producto-categoria/res/" underline="none">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  "& .ImagenIcon": {
                    backgroundImage: `url(${process.env.PUBLIC_URL}/imagenes/icons/icon1-1.png)`,
                  },
                }}
              >
                <img
                  className="ImagenIcon"
                  src={`${process.env.PUBLIC_URL}/imagenes/transp.png`}
                  width="120"
                  height="115"
                  alt="icons-1"
                />
              </Box>
              <Box sx={{ paddingTop: "27px" }}>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "20px",
                    lineheight: "1.2",
                    textTransform: "uppercase",
                    color: "#333",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  CORTES FINOS
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "15px",
                    lineHeight: "1.6",
                    textAlign: "center",
                    color: "#666",
                    padding: "0 5px",
                  }}
                >
                  Los cortes finos más jugosos para tus parrilladas.
                </Typography>
              </Box>
            </Link>
          </Box>

          <Box
            sx={{
              transition: "transform 1s, height 2s",
              padding: "10px 0",
              margin: "10px 0",
              width: { xs: "100%", md: "20%" },
              position: { xs: "unset", md: "absolute" },
              top: "0",
              left: "25%",
              "&:hover": {
                border: "1px solid #755956",
                backgroundColor: "#f7e4e2a8",
                borderRadius: "8px",
                borderStyle: "dashed",
                cursor: "pointer",
                transform: "scale(1.1)",
                "& .ImagenIcon": {
                  backgroundImage: `url(${process.env.PUBLIC_URL}/imagenes/icons/icon1.png)`,
                },
              },
            }}
          >
            <Link href="/producto-categoria/res/" underline="none">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  className="ImagenIcon"
                  src={`${process.env.PUBLIC_URL}/imagenes/icons/icon1-1.png`}
                  alt="icons-1"
                />
              </Box>
              <Box sx={{ paddingTop: "27px" }}>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "20px",
                    lineheight: "1.2",
                    textTransform: "uppercase",
                    color: "#333",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  RES
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "15px",
                    lineHeight: "1.6",
                    textAlign: "center",
                    color: "#666",
                  }}
                >
                  El sabor más exquisito para tus comidas.
                </Typography>
              </Box>
            </Link>
          </Box>

          <Box
            sx={{
              transition: "transform 1s, height 2s",
              padding: "10px 0",
              margin: "10px 0",
              width: { xs: "100%", md: "20%" },
              position: { xs: "unset", md: "absolute" },
              top: "0",
              right: "25%",
              "&:hover": {
                border: "1px solid #755956",
                backgroundColor: "#f7e4e2a8",
                borderRadius: "8px",
                borderStyle: "dashed",
                cursor: "pointer",
                transform: "scale(1.1)",
                "& .ImagenIcon": {
                  backgroundImage: `url(${process.env.PUBLIC_URL}/imagenes/icons/icon1.png)`,
                },
              },
            }}
          >
            <Link href="/producto-categoria/cerdo/" underline="none">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  className="ImagenIcon"
                  src={`${process.env.PUBLIC_URL}/imagenes/icons/icon1-1.png`}
                  alt="icons-1"
                />
              </Box>
              <Box sx={{ paddingTop: "27px" }}>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "20px",
                    lineheight: "1.2",
                    textTransform: "uppercase",
                    color: "#333",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  Cerdo
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "15px",
                    lineHeight: "1.6",
                    textAlign: "center",
                    color: "#666",
                  }}
                >
                  Calidad, sabor y servicio todo al alcance de su hogar.
                </Typography>
              </Box>
            </Link>
          </Box>

          <Box
            sx={{
              transition: "transform 1s, height 2s",
              padding: "10px 0",
              margin: "10px 0",
              width: { xs: "100%", md: "20%" },
              position: { xs: "unset", md: "absolute" },
              top: "24%",
              right: "0%",
              "&:hover": {
                border: "1px solid #755956",
                backgroundColor: "#f7e4e2a8",
                borderRadius: "8px",
                borderStyle: "dashed",
                cursor: "pointer",
                transform: "scale(1.1)",
                "& .ImagenIcon": {
                  backgroundImage: `url(${process.env.PUBLIC_URL}/imagenes/icons/icon1.png)`,
                },
              },
            }}
          >
            <Link href="/producto-categoria/cordero" underline="none">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  className="ImagenIcon"
                  src={`${process.env.PUBLIC_URL}/imagenes/icons/icon1-1.png`}
                  alt="icons-1"
                />
              </Box>
              <Box sx={{ paddingTop: "27px" }}>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "20px",
                    lineheight: "1.2",
                    textTransform: "uppercase",
                    color: "#333",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  CORDERO
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "15px",
                    lineHeight: "1.6",
                    textAlign: "center",
                    color: "#666",
                  }}
                >
                  Servicio a domicilio hasta la comodidad de su hogar.
                </Typography>
              </Box>
            </Link>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
}
export { AnuncioProductos };
export default AnuncioProductos;
