import React from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
} from "@mui/material";

import {
  Stars as StarsIcon,
  LocalGroceryStore as LocalGroceryStoreIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material/";

import { useTheme } from "@mui/material/styles";

import { ProductoImagen } from ".";

type color = "default" | "recetas";

interface CardProductosProps {
  title: string;
  imagen: string;
  informacion: string;
  color: color;
  items: any;
  onSelect: any;
}

function CardProductos({
  title,
  imagen,
  informacion,
  color = "default",
  items,
  onSelect,
}: CardProductosProps) {
  const theme = useTheme();
  const contenido = false;

  const handleSelect = () => {
    onSelect(items);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        transition: "transform 1s, height 2s",
        height: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
        display: "flex",
        backgroundColor:
          color === "default"
            ? theme.palette.error.main
            : theme.palette.success.main,
        "& .texto": {
          color: theme.palette.primary.contrastText,
          fontWeight: "600",
        },
        [theme.breakpoints.down("sm")]: {
          "& .MuiCardHeader-avatar": {
            display: "none",
          },
        },
        "&:hover": { transform: "scale(1.06)", cursor: "pointer" },
      }}
    >
      <CardHeader
        className="texto"
        avatar={<StarsIcon sx={{ xs: { display: "none" } }} />}
        title={title}
        sx={{
          height: "40px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordBreak: "break-word",
          "& .MuiTypography-root": { textTransform: "uppercase" },
        }}
      />
      <CardMedia sx={{ padding: theme.spacing(1) }}>
        <ProductoImagen src={imagen} marcadeagua={false} />
      </CardMedia>
      {contenido && informacion ? (
        <CardContent>
          <Typography className="texto" variant="body2" color="text.secondary">
            {informacion}
          </Typography>
        </CardContent>
      ) : null}
      <CardActions
        sx={{
          justifyContent: "center",
          marginTop: "auto",
          transition: "transform 4s, scale",
          "& .MuiButtonBase-root:hover": {
            transform: "scale(1.5)",
            cursor: "pointer",
          },
        }}
      >
        <IconButton size="small" className="texto" sx={{ display: "none" }}>
          <LocalGroceryStoreIcon />
        </IconButton>

        <IconButton size="small" className="texto" onClick={handleSelect}>
          <RemoveRedEyeIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
export { CardProductos };
export default CardProductos;
