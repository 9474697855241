import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useLocalstorage } from "../useComplements/useLocalstorage";
interface PrivateRouteProps {
  redirectPath?: string;
}

const ProtectedRoute = ({ redirectPath = "/admin" }: PrivateRouteProps) => {
  const { isAutorizate } = useLocalstorage();

  if (!isAutorizate()) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export { ProtectedRoute };
export default ProtectedRoute;
