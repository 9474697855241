import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AppProvider from "./providers/Appcontext";

import { ToastBar } from "./components/";
import {
  PageLogin,
  PageDashboard,
  PageHome,
  PageLProductosCategoria,
  PageLReceta,
  PageSReceta,
  PageBolsaTrabajo,
} from "./pages";
import { VerifyRoute, ProtectedRoute, HeaderPage } from "./components/";

function App() {
  return (
    <>
      <AppProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <HeaderPage /> <PageHome />
                </>
              }
            />
            <Route
              index
              element={
                <>
                  <HeaderPage /> <PageHome />
                </>
              }
            />
            <Route
              path="/bolsa-de-trabajo/"
              element={
                <>
                  <HeaderPage /> <PageBolsaTrabajo />
                </>
              }
            />
            <Route
              path="/producto-categoria/"
              element={<Navigate to={"/productos"} replace />}
            />
            <Route
              path="/producto-categoria/:categoria"
              element={
                <>
                  <HeaderPage />
                  <PageLProductosCategoria />
                </>
              }
            />
            <Route
              path="/buscar-producto/:useproducto"
              element={
                <>
                  <HeaderPage />
                  <PageLProductosCategoria />
                </>
              }
            />
            <Route
              path="/buscar-producto/"
              element={<Navigate to={"/productos"} replace />}
            />
            <Route
              path="/productos/"
              element={
                <>
                  <HeaderPage />
                  <PageLProductosCategoria />
                </>
              }
            />
            <Route
              path="/recetas/"
              element={
                <>
                  <HeaderPage />
                  <PageLReceta />
                </>
              }
            />
            <Route
              path="/recetas/:useReceta/:useTitulo"
              element={
                <>
                  <HeaderPage />
                  <PageSReceta />
                </>
              }
            />
            <Route element={<ProtectedRoute />}>
              <Route path="dashboard" element={<PageDashboard />} />
            </Route>
            <Route element={<VerifyRoute />}>
              <Route path="admin" element={<PageLogin />} />
            </Route>
            <Route path="*" element={<div>Aquí no hay nada: ¡404!</div>} />
          </Routes>
        </BrowserRouter>
        <ToastBar />
      </AppProvider>
    </>
  );
}

export default App;
