import React from "react";
import { Zoom, Fab, useScrollTrigger, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

function ScrollTop() {
  const theme = useTheme();

  const trigger = useScrollTrigger({
    target: window ?? undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{
          position: "fixed",
          bottom: "110px",
          right: "36px",
          zIndex: "999",
        }}
      >
        <Fab
          size="small"
          aria-label="scroll back to top"
          sx={{
            "&.MuiFab-root": {
              padding: theme.spacing(3),
              color: theme.palette.error.contrastText,
              backgroundColor: theme.palette.error.main,
            },
          }}
        >
          <ArrowUpwardIcon fontSize="large" />
        </Fab>
      </Box>
    </Zoom>
  );
}
export { ScrollTop };
export default ScrollTop;
