import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  LinearProgress,
  Grid,
  Typography,
  Stack,
  Pagination,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import { FloatingWhatsApp } from "react-floating-whatsapp";

import { useTheme } from "@mui/material/styles";

import { Stars as StarsIcon, Cancel as CancelIcon } from "@mui/icons-material/";

import {
  IconBreadcrumbs,
  ScrollTop,
  CardProductos,
  FilterBarr,
} from "../components";
import { usePeticiones } from "../useComplements";

interface producto {
  Id: string;
  producto: string;
  descripcion: string;
  beneficios: string;
  UPCCode: string;
  BarrCode: string;
  categoria_id: string;
  categoria: string;
  iva: number;
  precio: number;
  imagen: string;
  activo: boolean;
}

function PageLProductosCategoria() {
  const { peticionAsync } = usePeticiones();
  const { categoria, useproducto } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  let iconBread: any[] = [];

  function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    navigate(0);
  }

  if (useproducto) {
    iconBread = [
      <Link
        underline="hover"
        key="1"
        color="inherit"
        href="/"
        onClick={handleClick}
      >
        Home
      </Link>,
      <Link
        underline="hover"
        key="2"
        color="inherit"
        href="/productos/"
        onClick={handleClick}
      >
        Productos
      </Link>,
      <Typography key="3" color="text.primary">
        {` ${useproducto[0].toUpperCase().replaceAll("-", " ")}${useproducto
          .substring(1)
          .toLowerCase()
          .replaceAll("-", " ")}`}
      </Typography>,
    ];
  } else if (categoria) {
    iconBread = [
      <Link
        underline="hover"
        key="1"
        color="inherit"
        href="/"
        onClick={handleClick}
      >
        Home
      </Link>,
      <Link
        underline="hover"
        key="2"
        color="inherit"
        href="/productos/"
        onClick={handleClick}
      >
        Productos
      </Link>,
      <Typography
        key="3"
        color="text.primary"
        component="div"
        sx={{ color: "unset", fontSize: "unset" }}
      >
        Categoría :
        <strong>
          {` ${categoria[0].toUpperCase()}${categoria
            .substring(1)
            .toLowerCase()}`}
        </strong>
      </Typography>,
    ];
  } else {
    iconBread = [
      <Link
        underline="hover"
        key="1"
        color="inherit"
        href="/"
        onClick={handleClick}
      >
        Home
      </Link>,
      <Typography key="3" color="text.primary">
        Productos
      </Typography>,
    ];
  }

  const [productos, setProductos] = React.useState<producto[]>([]);
  const [productosSearch, setProductosSearch] = React.useState<string>(
    categoria
      ? categoria
      : useproducto?.replaceAll("-", " ").toUpperCase() ?? ""
  );

  const [filterOrden, setFilterOrden] = React.useState<string>("");
  const [countPage, setCountPage] = React.useState<number>(0);
  const [page, setPage] = React.useState(1);
  const [Rango1, setRango1] = React.useState<number>(0);
  const [Rango2, setRango2] = React.useState<number>(0);
  const ROWPAGE = 20;

  const [mount, setMount] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState<boolean>(false);
  const [select, setSelect] = React.useState<any>({ titulo: "", imagen: "" });

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setPage(value);
  };

  const handleChangeRecetaShow = (item: producto) => {
    setSelect({
      titulo: item.producto,
      imagen: item.imagen.replaceAll("_mini", ""),
    });
    setOpen(true);
  };

  const DialogoImagen = (
    <Dialog onClose={handleClose} open={open} maxWidth="md">
      <DialogTitle
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <StarsIcon fontSize="small" />
        {select.titulo}
        <IconButton
          sx={{ position: "absolute", right: "10px", top: "10px" }}
          onClick={handleClose}
        >
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          "& img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={select.imagen} alt={select.titulo} />
      </DialogContent>
    </Dialog>
  );

  React.useEffect(() => {
    const body = {};

    const fetchdata = async (info: any) => {
      const result: any = await peticionAsync({
        accion: "admin/json-cat/productos/list",
        formData: info,
      });

      if (result.success) {
        setProductosSearch(categoria ?? "");

        const productTmp: producto[] = result.data.data;
        const FilterProd = productTmp.filter((product: producto) => {
          if (productosSearch && (categoria || useproducto)) {
            if (categoria) {
              return (
                product.categoria.toLowerCase() ===
                productosSearch.toLowerCase()
              );
            }
            return product.producto
              .toLowerCase()
              .includes(productosSearch.toLowerCase());
          }
          return true;
        });
        setProductos(FilterProd);
        let IniCountPage = Math.ceil(FilterProd.length / ROWPAGE);

        if (categoria) {
          IniCountPage = Math.ceil(
            FilterProd.filter((a: producto) =>
              a.categoria.toLowerCase().includes(productosSearch.toLowerCase())
            ).length / ROWPAGE
          );
        } else if (useproducto) {
          IniCountPage = Math.ceil(
            FilterProd.filter((a: producto) =>
              a.producto.toLowerCase().includes(productosSearch.toLowerCase())
            ).length / ROWPAGE
          );
        }
        setCountPage(IniCountPage);
      }
    };

    setRango1(page * ROWPAGE - ROWPAGE);
    setRango2(page * ROWPAGE);

    if (!mount) {
      setMount(true);
      fetchdata(body);
    }
  }, [mount, peticionAsync, page, productosSearch, categoria, useproducto]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setMount(false);
    }, 10);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Container
        id="back-to-top-anchor"
        sx={{
          marginTop: { xs: "220px", md: "230px" },

          "& .styles-module_whatsappButton__tVits": {
            right: ".9rem",
          },
        }}
      >
        {mount === false ? (
          <Box>
            <LinearProgress />
          </Box>
        ) : (
          <>
            <ScrollTop />
            <IconBreadcrumbs breadcrumbs={iconBread} />
            <FilterBarr
              filter={[filterOrden, setFilterOrden]}
              message={
                countPage > 1
                  ? `Pagina: ${page} de ${countPage} `
                  : `Pagina: ${countPage}`
              }
            />
            <Grid container>
              {productos ? (
                <>
                  {productos
                    .sort((a: producto, b: producto) => {
                      if (filterOrden === "PaMenor") return a.precio - b.precio;
                      if (filterOrden === "PaMayor") return b.precio - a.precio;
                      if (filterOrden === "NombreZA")
                        return a.producto > b.producto ? -1 : 1;
                      return a.producto > b.producto ? 1 : -1;
                    })
                    .filter((a, index) => index >= Rango1 && index < Rango2)
                    .map((item: producto, index: number) => (
                      <Grid
                        item
                        key={`item-producto-${index}`}
                        xs={6}
                        sm={4}
                        md={3}
                        sx={{ padding: "8px" }}
                      >
                        <CardProductos
                          title={item.producto}
                          imagen={item.imagen}
                          informacion={item.descripcion}
                          color="default"
                          items={item}
                          onSelect={handleChangeRecetaShow}
                        />
                      </Grid>
                    ))}
                  <Grid item xs={12} justifyContent="center">
                    <Stack
                      spacing={2}
                      sx={{
                        margin: theme.spacing(3, 0, 6),
                        "& .MuiPaginationroot": {
                          display: "flex",
                          justifyContent: "center",
                        },
                      }}
                    >
                      <Pagination
                        count={countPage}
                        page={page}
                        onChange={handleChange}
                        className="Guillermo"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          "& .MuiPaginationItemroot.Muiselected ": {
                            color: theme.palette.error.contrastText,
                            backgroundColor: theme.palette.error.light,
                          },
                        }}
                      />
                    </Stack>
                  </Grid>
                </>
              ) : (
                <Grid>
                  <Typography>No hay Resultados...</Typography>
                </Grid>
              )}
            </Grid>
            <FloatingWhatsApp
              phoneNumber="+526693335323"
              accountName="Carnes Mi Esperanza"
              chatMessage="¡Hola! 🤝 ¿Cómo podemos ayudar?"
              statusMessage="Normalmente responde en 20 minutos."
              avatar={`${process.env.PUBLIC_URL}/icons/favicon-96x96.png`}
              allowEsc
              allowClickAway
              notification
              notificationSound
            />
          </>
        )}
        {DialogoImagen}
      </Container>
    </>
  );
}
export { PageLProductosCategoria };
export default PageLProductosCategoria;
