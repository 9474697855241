import * as React from "react";
import { Dialog, DialogTitle, IconButton } from "@mui/material/";
import { useTheme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";

export interface ModDialogProps {
  maxWidth: "xs" | "sm" | "md" | "lg" | "xl";
  fullWidth: boolean;
  open: boolean;
  handleClose: () => void;
  modulo: moduloActive;
}

export interface moduloActive {
  titulo: string;
  descripcion: string;
  outlet: JSX.Element;
}

function ModDialog(props: ModDialogProps) {
  const { maxWidth, fullWidth, open, handleClose, modulo } = props;
  const theme = useTheme();

  const { titulo, outlet } = modulo;

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle
        sx={{
          bgcolor: blue[900],
          color: theme.palette.primary.contrastText,
          fontSize: "1rem",
        }}
      >
        {titulo}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {outlet}
    </Dialog>
  );
}

export { ModDialog };
export default ModDialog;
