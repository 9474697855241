import * as React from "react";
import {
  FormControl,
  Input,
  InputLabel,
  Button,
  Box,
  DialogActions,
  DialogContent,
} from "@mui/material";

import {
  AddCircle as AddCircleIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material/";

import { useAppProvider } from "../../../providers/Appcontext";
import { ModLists } from "../";

import { usePeticiones, useValidaForm } from "../../../useComplements";

interface dataState {
  categoria_id: number;
  categoria: string;
}

interface inputsState {
  categoria: string;
  activo: boolean;
}

interface modifyState {
  list: boolean;
  edit: boolean;
  id: number | null | string;
}

interface ModProductosCategoriaProps {}

function ModProductosCategoria(props: ModProductosCategoriaProps) {
  const { setNotificacionMsg, setNotificacionAcc } = useAppProvider();
  const { peticionAsync } = usePeticiones();
  const { validaContenido } = useValidaForm();

  const iniDatos = () => {
    const datos: inputsState = {
      categoria: "",
      activo: false,
    };
    return datos;
  };

  const [mount, setMount] = React.useState<boolean>(true);
  const [data, setData] = React.useState<dataState[]>([]);
  const [inputs, setInputs] = React.useState<inputsState>(iniDatos());
  const routes: string = "admin/json-cat/categorias";

  const columnsHead = [
    {
      field: "categoria",
      headerName: "Categoria",
      minWidth: 150,
      flex: 1,
    },
  ];

  const [modify, setModify] = React.useState<modifyState>({
    list: true,
    edit: false,
    id: null,
  });

  // -------------------------------------
  // Control de Acciones Grabado DataBase
  // -------------------------------------

  const handleClickAdd = () => {
    const body = {
      categoria: inputs.categoria.toLocaleUpperCase("en-US"),
    };

    const fetchAdd = async (info: any) => {
      const result: any = await peticionAsync({
        accion: `${routes}/add`,
        formData: info,
      });

      if (result.success) {
        setMount(false);
        setNotificacionAcc("success");
        setNotificacionMsg("Registro agreagado exitosa.");
        setInputs(iniDatos());
        setModify({ list: true, edit: false, id: null });
      } else {
        setNotificacionAcc("warning");
        setNotificacionMsg("Registro agreagado fallido.");
        setInputs({ ...inputs, activo: false });
      }
    };

    const valida = validaContenido(body, modify.edit ? "edit" : "");
    if (valida.status) {
      setInputs({ ...inputs, activo: true });
      fetchAdd(body);
    } else {
      setNotificacionAcc(valida.alert.accion);
      setNotificacionMsg(valida.alert.message);
      setInputs({ ...inputs, activo: false });
    }
  };

  const handleClickEdit = (id: any) => {
    setModify({ list: false, edit: true, id: id });

    const reesult: any = data.filter((a: any) => a.id === id)[0];

    const body = {
      categoria: reesult.categoria.toLocaleUpperCase("en-US"),
      activo: false,
    };
    setInputs(body);
  };

  const handleClickDelete = (id: any) => {
    const body = { id: id };
    setInputs({ ...inputs, activo: true });

    const fetchDelete = async (info: any) => {
      const result: any = await peticionAsync({
        accion: `${routes}/delete`,
        formData: info,
      });

      if (result.success) {
        setMount(false);
        setNotificacionAcc("success");
        setNotificacionMsg("Eliminación correctamente.");
        setInputs(iniDatos());
        setModify({ list: true, edit: false, id: null });
      } else {
        setNotificacionAcc("error");
        setNotificacionMsg("Eliminación no realizada.");
        setInputs({ ...inputs, activo: false });
      }
    };
    fetchDelete(body);
  };

  const handleClickGrabar = (key: number | null | string) => {
    const body = {
      id: key,
      categoria: inputs.categoria.toLocaleUpperCase("en-US"),
    };

    setInputs({ ...inputs, activo: true });

    const fetchUpdate = async (info: any) => {
      const result: any = await peticionAsync({
        accion: `${routes}/update`,
        formData: info,
      });

      if (result.success) {
        setMount(false);
        setNotificacionAcc("success");
        setNotificacionMsg("Actualización exitosa.");
        setInputs(iniDatos());
        setModify({ list: true, edit: false, id: null });
      } else {
        setNotificacionAcc("success");
        setNotificacionMsg("Actualización fallida.");
        setInputs({ ...inputs, activo: false });
      }
    };

    const valida = validaContenido(body, modify.edit ? "edit" : "");

    if (valida.status) {
      setInputs({ ...inputs, activo: true });
      fetchUpdate(body);
    } else {
      setNotificacionAcc(valida.alert.accion);
      setNotificacionMsg(valida.alert.message);
      setInputs({ ...inputs, activo: false });
    }
  };

  // -------------------------------------
  // Fin de Acciones Grabado DataBase
  // -------------------------------------

  const handleChange =
    (prop: keyof inputsState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputs({ ...inputs, [prop]: event.target.value });
    };

  const handleChangeActiveAdd = () => {
    setModify({ list: false, edit: false, id: null });
  };

  const handleClickEditCancel = () => {
    setInputs(iniDatos());
    setModify({ list: true, edit: false, id: null });
  };

  React.useEffect(() => {
    const body = {};

    const fetchdata = async (info: any) => {
      const result: any = await peticionAsync({
        accion: `${routes}/list`,
        formData: info,
      });
      if (result.success) {
        setData(result.data);
      }
    };

    if (!mount) {
      setMount(true);
      fetchdata(body);
    }
  }, [mount, peticionAsync]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setMount(false);
    }, 10);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {modify.list ? (
        <>
          {data.length > 0 ? (
            <>
              <ModLists
                rowsList={data}
                columnsHead={columnsHead}
                selectRow={handleClickEdit}
                deleteRow={handleClickDelete}
              />
              <DialogActions>
                <Button
                  onClick={handleChangeActiveAdd}
                  variant="contained"
                  size="small"
                  endIcon={<AddCircleIcon />}
                >
                  Agregar
                </Button>
              </DialogActions>
            </>
          ) : null}
        </>
      ) : (
        <>
          <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: { sm: "flex" },
                flexDirection: "column",
                height: "400px",
                maxHeight: "600px",
                width: "100%",
                "& input": { textTransform: "uppercase" },
              }}
            >
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="input-categoria">Categoria</InputLabel>
                <Input
                  autoComplete="off"
                  id="input-categoria"
                  type="text"
                  value={inputs.categoria}
                  onChange={handleChange("categoria")}
                  fullWidth
                  disabled={inputs.activo}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            {modify.edit ? (
              <>
                <Button
                  onClick={() => handleClickGrabar(modify.id)}
                  variant="contained"
                  endIcon={<CheckCircleIcon />}
                  sx={{ mr: 1 }}
                >
                  Grabar
                </Button>
                <Button
                  onClick={handleClickEditCancel}
                  variant="outlined"
                  endIcon={<CancelIcon />}
                >
                  Cancelar
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={handleClickEditCancel}
                  variant="outlined"
                  endIcon={<CancelIcon />}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={handleClickAdd}
                  variant="contained"
                  endIcon={<AddCircleIcon />}
                >
                  Agregar
                </Button>
              </>
            )}
          </DialogActions>
        </>
      )}
    </>
  );
}

export { ModProductosCategoria };
export default ModProductosCategoria;
