import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as ReactLogo } from "../svg/logo-mi-esperanza-completo.svg";

export interface ProductoImagenProps {
  src: string;
  marcadeagua: boolean;
}

function ProductoImagen({ src, marcadeagua }: ProductoImagenProps) {
  const imgUrl = src;
  const theme = useTheme();

  return (
    <>
      {imgUrl ? (
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            backgroundSize: "auto 100%",
            backgroundPosition: "center",
            "& .Producto": { width: "100%" },
          }}
        >
          <Box
            sx={{
              padding: theme.spacing(1),
              position: "relative",
              "& .MarcaAgua": {
                width: "40%",
                position: "absolute",
                opacity: ".25",
                padding: theme.spacing(1),
                right: "0",
                top: -3,
              },
            }}
          >
            <img src={imgUrl} alt={imgUrl} className="Producto" />
            {marcadeagua === true ? <ReactLogo className="MarcaAgua" /> : null}
          </Box>
        </Box>
      ) : null}
    </>
  );
}
export { ProductoImagen };
export default ProductoImagen;
